import React, { useState, useEffect } from "react";
import { Prompt, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { generalConstants, learningCentreConstants, DESKTOP_MIN_WIDTH, MOBILE_MAX_WIDTH } from "_constants";
import { learningCentreService } from "services";
import { history } from "_util/_helper";
import _ from "lodash";
import moment from "moment";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";
import {
	SubmitButton,
	CancelButton,
	CategoryButton,
	Loading,
	Select,
	TextField,
	NewCategoryDialog,
	SingleImageUploader,
	BlockMobileOverlay,
} from "components/general";
import Button from "@material-ui/core/Button";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AddCircleOutline, Close } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { Desktop } from "components/responsive";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";

export const EditCentrePage = () => {
	const location = useLocation();

	const [prevPage, setPrevPage] = useState(location?.state?.from);
	const [cloneCenter, setCloneCenter] = useState(location?.state?.cloneCenter);
	const [sourceCenter, setSourceCenter] = useState(location?.state?.sourceCenter);
	const [key, setCenterKey] = useState(location?.state?.sourceCenter?._key);
	const loggedIn = useSelector(({ auth }) => auth.loggedIn);
	const isFetching = useSelector(({ centre }) => centre.isFetching);
	const [createData, setCreateData] = useState(null);
	const [updateData, setUpdateData] = useState(null);
	const [isCreateFetching, setCreateFetching] = useState(false);
	const [isUpdateFetching, setUpdateFetching] = useState(false);

	const initForm = {
		key: "",
		createdBy: "",
		createdTime: "",
		category: [],
		name: "",
		nameEng: "",
		description: "",
		status: learningCentreConstants.OPTION.status[0].key,
		profilePic: "",
		address: "",
		district: "",
		openingHours: "",
		email: "",
		phone: "",
		website: "",
		whatsapp: "",
		ig: "",
		facebook: "",
	};

	const dispatch = useDispatch();

	const [center, setCenter] = useState({});
	const [formData, setFormData] = useState(initForm);
	const [isLoading, setIsLoading] = useState(false);
	const [isChanged, setIsChanged] = useState(false);
	const [isSaved, setIsSaved] = useState(false);
	const [isNewCategoryShow, setIsNewCategoryShow] = useState(false);

	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(2 * 1000));

	useEffect(() => {
		setPrevPage(location?.state?.from);
		setCloneCenter(location?.state?.cloneCenter);
		setSourceCenter(location?.state?.sourceCenter);
		setCenterKey(location?.state?.sourceCenter?._key);
	}, [location?.state]);

	useEffect(() => {
		if (loggedIn) {
			if ((updateData && updateData._key === center._key) || createData) {
				return;
			} else if (key) {
				getCenterByKey(false);
			}
		}
	}, [dispatch, loggedIn]);

	useEffect(() => {
		if ((updateData && updateData._key === center._key) || createData || !loggedIn) {
			return;
		}
		if (!_.isEmpty(center) && sourceCenter && !_.isEmpty(sourceCenter)) {
			if (moment(sourceCenter.lastUpdateTime).isAfter(center.lastUpdateTime)) {
				getCenterByKey(true);
				return;
			}
		}
		if (!_.isEmpty(center) || !_.isEmpty(cloneCenter)) {
			const currentCenter = !_.isEmpty(cloneCenter) ? cloneCenter : !_.isEmpty(center) ? center : null;
			const isClone = !_.isEmpty(cloneCenter);
			if (currentCenter != null && !_.isEmpty(currentCenter)) {
				const data = {
					key: currentCenter._key,
					rev: currentCenter._rev,
					createdBy: currentCenter.createBy || "",
					createdTime: currentCenter.createdTime,
					category: currentCenter.category || [],
					name: isClone && !_.isEmpty(currentCenter.name) ? `(複製)${currentCenter.name}` : currentCenter.name || "",
					nameEng: isClone && !_.isEmpty(currentCenter.nameEng) ? `(Copy)${currentCenter.nameEng}` : currentCenter.nameEng || "",
					description: currentCenter.description,
					status: currentCenter.status,
					profilePic: currentCenter.profilePic || "",
					address: currentCenter.address,
					district: currentCenter.district,
					openingHours: currentCenter.openingHours,
					email: currentCenter.email,
					phone: currentCenter.phone,
					website: currentCenter.website,
					whatsapp: currentCenter.whatsapp,
					ig: currentCenter.ig,
					facebook: currentCenter.facebook,
				};
				setFormData(data);
				setIsChanged(!_.isEmpty(cloneCenter));
			}
		}
	}, [center, cloneCenter, loggedIn]);

	useEffect(() => {
		if (isCreateFetching || isUpdateFetching) {
			debouncer.token();
		}
	}, [isCreateFetching, isUpdateFetching]);

	useEffect(() => {
		if ((updateData && updateData._key === center._key) || createData) {
			history.goBack();
		}
	}, [updateData, createData]);

	const getCenterByKey = (forceUpdate) => {
		setIsLoading(true);
		learningCentreService.getLearningCenterByKeyWithoutData(key).then(
			(response) => {
				setIsLoading(false);
				if (response) {
					setCenter(response);
					if (forceUpdate) {
						setSourceCenter(response);
					}
				}
			},
			(error) => {
				setIsLoading(false);
				toast.error(error.error);

				if (error.error?.status !== 401) {
					history.push(generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT);
				}
			}
		);
	};

	const handleBack = () => {
		history.goBack();
	};

	const handleChange = ({ key, value }) => {
		let tempData = { ...formData };
		tempData[key] = value;
		setFormData(tempData);
		setIsChanged(true);
	};

	const handleChangeImage = (file) => {
		setFormData({ ...formData, profilePic: file });
		setIsChanged(true);
	};

	const handleClearImage = () => {
		setFormData({ ...formData, profilePic: "" });
		setIsChanged(true);
	};

	const handleNewButtonOnClick = () => {
		setIsNewCategoryShow(true);
	};

	const handleCloseNewCategory = () => {
		setIsNewCategoryShow(false);
	};

	const handleSubmitNewCategory = (value) => {
		setFormData({ ...formData, category: value });
		setIsChanged(true);
	};

	const handleRemoveCategory = (value) => {
		if (formData.category.length > 0) {
			setFormData({
				...formData,
				category: formData.category.filter((category) => {
					return category !== value;
				}),
			});
			setIsChanged(true);
		}
	};

	const handleSubmit = () => {
		var data = {
			key: formData.key || "",
			rev: formData.rev || "",
			category: formData.category || "",
			name: formData.name || "",
			nameEng: formData.nameEng || "",
			description: formData.description || "",
			status: formData.status || "",
			profilePic: formData.profilePic || "",
			address: formData.address || "",
			district: formData.district || "",
			openingHours: formData.openingHours || "",
			email: formData.email || "",
			phone: formData.phone || "",
			website: formData.website || "",
			whatsapp: formData.whatsapp || "",
			ig: formData.ig || "",
			facebook: formData.facebook || "",
		};
		if (formData.category) {
			data = {
				...data,
				category: JSON.stringify(formData.category)
					.replace(/[\]\["]/g, "")
					.trim(),
			};
		}

		if (!debouncer.token()) {
			toast.error(`請稍後再試`);
			return;
		}

		if (!_.isEmpty(center) && !cloneCenter) {
			setUpdateFetching(true);
			learningCentreService.updateLearningCenter(data).then(
				(response) => {
					if (response) {
						if (!isSaved) {
							setIsSaved(true);
						}
						setUpdateFetching(false);
						setUpdateData(response);
						toast.success("已成功更新");
					}
				},
				(error) => {
					setUpdateFetching(false);
					toast.error(error.error);
				}
			);
		} else {
			setCreateFetching(true);
			learningCentreService.createLearningCenter(data).then(
				(response) => {
					if (response) {
						if (!isSaved) {
							setIsSaved(true);
						}
						setCreateFetching(false);
						setCreateData(response);
						toast.success("已成功新增");
					}
				},
				(error) => {
					setCreateFetching(false);
					toast.error(error.error);
				}
			);
		}
	};

	const renderCategory = () => {
		return formData.category.map((item, index) => {
			return (
				<div key={`${item}_${index}_category_item`} style={{ marginRight: "5px", marginBottom: "5px" }}>
					<CategoryButton
						label={item}
						buttonIcon={
							<IconButton
								style={{ paddingTop: 5, paddingBottom: 5 }}
								onClick={() => {
									handleRemoveCategory(item);
								}}
							>
								<Close style={{ fontSize: "16px", color: "#FFFFFF" }} />
							</IconButton>
						}
					/>
				</div>
			);
		});
	};
	const renderCategoryList = renderCategory();

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({
			title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key],
			isAction: true,
			target: generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT,
		});

		if (!_.isEmpty(center) && !cloneCenter) {
			pathArray.push({
				title: _.isEmpty(center.name) ? center.nameEng || "" : center.name || "",
				isBack: true,
				target: generalConstants.PATH.LEARNING_CENTER,
				stateObj: { from: prevPage, sourceCenter: center },
			});
		}
		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					!_.isEmpty(center) && !cloneCenter
						? generalConstants.NAV_TAB.EDIT_LEARNING_CENTRE.key
						: generalConstants.NAV_TAB.CREATE_LEARNING_CENTRE.key
				],
			isAction: false,
			target: "",
		});
		return pathArray;
	};

	return (
		<React.Fragment>
			<NewCategoryDialog
				category={formData.category || []}
				active={isNewCategoryShow}
				handleOnSubmit={handleSubmitNewCategory}
				handleOnClose={handleCloseNewCategory}
			/>
			{(!!isLoading || !!isCreateFetching || !!isUpdateFetching || !!isFetching) && <Loading />}
			<Prompt when={isChanged && !isSaved && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<AdminSectionbar
						title={
							!_.isEmpty(center) && !cloneCenter
								? generalConstants.NAV_TAB.EDIT_LEARNING_CENTRE.key
								: generalConstants.NAV_TAB.CREATE_LEARNING_CENTRE.key
						}
						locationPathArray={getLocationPath()}
					/>
					<ContentContainer padding="0px 0px 17px">
						<ContentColumnsContainer>
							<ContentLeft>
								<BlockGroup>
									<Block flex="0 0 100px">
										<TextFieldLabel style={{ marginLeft: "15px" }}>補習社徽標</TextFieldLabel>
										<Block>
											<SingleImageUploader
												src={formData.profilePic}
												handleChange={handleChangeImage}
												handleClear={handleClearImage}
												uploadLabel="上傳圖片"
												noImageLabel="沒有圖片"
											/>
										</Block>
									</Block>
									<LineRight />
									<Block style={{ marginLeft: "10px" }}>
										<TextFieldLabel>補習社名稱 (中文)</TextFieldLabel>
										<TextField placeholder="補習社名稱 (中文)" value={formData.name} name="name" handleChange={handleChange} />
										<TextFieldLabel style={{ marginTop: "10px" }}>Learning Centre Name (English)</TextFieldLabel>
										<TextField
											placeholder="Learning Centre Name (English)"
											value={formData.nameEng}
											name="nameEng"
											handleChange={handleChange}
										/>
									</Block>
								</BlockGroup>

								<div>
									<BlockGroupDetail>
										<Block flex="0 0 320px">
											<TextFieldLabel>補習社簡介</TextFieldLabel>
											<TextField
												placeholder="補習社簡介"
												multiline
												rows={10}
												value={formData.description}
												name="description"
												handleChange={handleChange}
											/>
											<TextFieldLabel style={{ marginTop: "10px" }}>課程類別</TextFieldLabel>
											{formData.category.length > 0 && <CategoryGroup>{renderCategoryList}</CategoryGroup>}
											<AddButtonContainer>
												<Button
													color="primary"
													startIcon={<AddCircleOutline style={{ fontSize: "20px", color: "#000000" }} />}
													onClick={() => {
														handleNewButtonOnClick();
													}}
												>
													<AddButtonText>新增</AddButtonText>
												</Button>
											</AddButtonContainer>
										</Block>
										<LineRight style={{ marginTop: "5px" }} />
										<Block>
											<TextFieldLabel>營業時間</TextFieldLabel>
											<TextField
												placeholder="營業時間"
												multiline
												rows={16}
												value={formData.openingHours}
												name="openingHours"
												handleChange={handleChange}
											/>
										</Block>
									</BlockGroupDetail>
								</div>
							</ContentLeft>
							<ContentRight>
								<div style={{ minHeight: "460px" }}>
									<Block>
										<TextFieldLabel>地址</TextFieldLabel>
										<TextField placeholder="地址" multiline rows={3} value={formData.address} name="address" handleChange={handleChange} />
									</Block>
									<Block>
										<TextFieldLabel>地區</TextFieldLabel>
										<React.Fragment>
											<Select
												name={"district"}
												options={learningCentreConstants.OPTION.district}
												value={formData.district}
												handleChange={handleChange}
											/>
										</React.Fragment>
									</Block>

									<BlockGroup>
										<Block>
											<TextFieldLabel>電話</TextFieldLabel>
											<TextField placeholder="電話" value={formData.phone} name="phone" handleChange={handleChange} />
										</Block>
										<Block>
											<TextFieldLabel>電郵</TextFieldLabel>
											<TextField placeholder="電郵" value={formData.email} name="email" handleChange={handleChange} />
										</Block>
									</BlockGroup>

									<BlockGroup>
										<Block>
											<TextFieldLabel>網址</TextFieldLabel>
											<TextField placeholder="網址" value={formData.website} name="website" handleChange={handleChange} />
										</Block>
										<Block>
											<TextFieldLabel>Whatsapp</TextFieldLabel>
											<TextField placeholder="Whatsapp" value={formData.whatsapp} name="whatsapp" handleChange={handleChange} />
										</Block>
									</BlockGroup>

									<BlockGroup>
										<Block>
											<TextFieldLabel>Facebook 專頁</TextFieldLabel>
											<TextField placeholder="Facebook 專頁" value={formData.facebook} name="facebook" handleChange={handleChange} />
										</Block>
										<Block>
											<TextFieldLabel>Instagram</TextFieldLabel>
											<TextField placeholder="Instagram" value={formData.ig} name="ig" handleChange={handleChange} />
										</Block>
									</BlockGroup>

									<Block>
										<TextFieldLabel>狀態</TextFieldLabel>
										<React.Fragment>
											<Select
												name={"status"}
												options={learningCentreConstants.OPTION.status}
												value={formData.status}
												handleChange={handleChange}
											/>
										</React.Fragment>
									</Block>
								</div>
								<RowInBlock style={{ justifyContent: "flex-end" }}>
									<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
									<SubmitButton
										label={"確認"}
										style={{ marginRight: "15px" }}
										handleOnClick={handleSubmit}
										active={(!isCreateFetching && isChanged) || (!isUpdateFetching && isChanged)}
										disabled={isCreateFetching || isUpdateFetching}
									/>
								</RowInBlock>
							</ContentRight>
						</ContentColumnsContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
			<BlockMobileOverlay />
		</React.Fragment>
	);
};

const RowInBlock = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: row;
`;

const ContentColumnsContainer = styled.div`
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		flex-direction: row;
		display: flex;
	}
`;

const ContentLeft = styled.div`
	flex: 1;
	padding: 10px;
	border-right: 1px solid #dddddd;
`;

const ContentRight = styled.div`
	flex: 1;
	padding: 10px;
`;

const LineRight = styled.div`
	border-right: 1px solid #dddddd;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const BlockGroupDetail = styled.div`
	display: flex;
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		flex-direction: row;
	}
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex-direction: column;
	}
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const CategoryGroup = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const TextFieldLabel = styled.div`
	color: #000000;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 7px;
	white-space: nowrap;
`;

const AddButtonContainer = styled.div`
	flex: 1;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: #333333;
`;

const AddButtonText = styled.span`
	width: 84px;
	height: 20px;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
`;
