import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { learningCoursesConstants } from "_constants";

export const CourseStatus = (props) => {
	const { course, style } = props;

	const text = learningCoursesConstants.DATA.status[course.status]?.label;
	if (course.deleted === true) {
		return <DeleteBadge style={style}>{"已移除"}</DeleteBadge>;
	} else if (course.status === learningCoursesConstants.DATA.status.OPEN.key) {
		return <OpenStatus style={style}>{text}</OpenStatus>;
	} else if (course.status === learningCoursesConstants.DATA.status.CANCELLED.key) {
		return <CancelStatus style={style}>{text}</CancelStatus>;
	} else if (course.status === learningCoursesConstants.DATA.status.SUSPENDED.key) {
		return <SuspendedStatus style={style}>{text}</SuspendedStatus>;
	} else if (course.status === learningCoursesConstants.DATA.status.CLOSED.key) {
		return <InactiveBadge style={style}>{text}</InactiveBadge>;
	} else {
		return <div></div>;
	}
};

const RootStatus = styled.div`
	padding: 3px 0px;
	background-color: red;
	text-align: center;
	border-radius: 3px;
	max-width: 60px;
	margin: 0 auto;
	font-size: 11px;
`;

const SuspendedStatus = styled(RootStatus)`
	color: black;
	background-color: #ffd95c;
`;
const OpenStatus = styled(RootStatus)`
	color: black;
	background-color: #5cff82;
`;
const InactiveBadge = styled(RootStatus)`
	color: #ff0000;
	background-color: #fff0f2;
`;
const DeleteBadge = styled(RootStatus)`
	color: white;
	background-color: #ff5c5c;
`;
const CancelStatus = styled(RootStatus)`
	color: black;
	background-color: #ffd95c;
`;

CourseStatus.propTypes = {
	course: PropTypes.object,
	style: PropTypes.object,
};
