import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useKey } from "_util/hook";
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { YellowButton } from "components/general";
import { MOBILE_MAX_WIDTH, generalConstants } from "_constants";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

export const EmailVerifyRequestForm = (props) => {
	const { open, handleSendVerifyEmail, onClose } = props;
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));
	const recaptchaRef = useRef(null);
	const [formData, setFormData] = useState({ email: "" });

	const isFetching = useSelector(({ auth }) => auth.isFetching);
	const forgetPasswordSuccess = useSelector(({ auth }) => auth.forgetPasswordSuccess);

	const enterPress = useKey("enter");

	useEffect(() => {
		if (enterPress && open) {
			handleOnClick();
		}
	}, [enterPress]);

	useEffect(() => {
		if (recaptchaRef.current && !isFetching && !forgetPasswordSuccess) {
			setFormData({ ...formData, recaptchaValue: undefined });
		}
	}, [isFetching, forgetPasswordSuccess]);

	const handleOnClick = async () => {
		if (!debouncer.token()) {
			return;
		}
		const token = await recaptchaRef.current.executeAsync();
		recaptchaRef.current.reset();
		handleSendVerifyEmail({ recaptchaValue: token });
	};

	return (
		<Dialog open={open} onClose={onClose} BackdropProps={{ style: { backgroundColor: "#000000cc" } }}>
			<DialogContent style={{ paddingBottom: 20, paddingRight: 0, paddingTop: 0 }}>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<ForgetPasswordTitle style={{ paddingTop: 20 }}>登入失敗</ForgetPasswordTitle>
					<CloseButton onClick={onClose}>
						<CloseIcon style={{ marginRight: 15, marginTop: 15 }} />
					</CloseButton>
				</div>
				<SubTitle>請先認證你的帳戶。</SubTitle>
			</DialogContent>
			<BlueBottom>
				<DialogContent style={{ paddingTop: 15 }}>
					<WhiteText>如需協助，請與我們聯絡：</WhiteText>
					<WhiteText>
						電郵：{" "}
						<FooterCSEMail target="_black" href={generalConstants.PATH.CONTACT_US}>
							cs@squarebox.com.hk
						</FooterCSEMail>
					</WhiteText>
				</DialogContent>
				<DialogActions style={{ display: "block", padding: 20 }}>
					<ReCaptchaWithButton>
						<ReCaptchaContainer>
							<ReCAPTCHA badge={"inline"} ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} size={"invisible"} hl="zh-HK" />
						</ReCaptchaContainer>
						<ButtonContainer>
							<YellowButton
								handleOnClick={handleOnClick}
								label={"重新發送驗證電郵"}
								active={!isFetching}
								disabled={isFetching}
								style={{ width: 180 }}
							></YellowButton>
						</ButtonContainer>
					</ReCaptchaWithButton>
				</DialogActions>
			</BlueBottom>
		</Dialog>
	);
};

EmailVerifyRequestForm.propTypes = {
	handleSendVerifyEmail: PropTypes.func,
	onClose: PropTypes.func,
	open: PropTypes.bool,
};

const ForgetPasswordTitle = styled.div`
	color: #112a84;
	font-size: 36px;
	font-weight: 900;
	line-height: 1.5;
`;

const SubTitle = styled.div`
	color: #333333;
	font-size: 16px;
	line-height: 20px;
`;

const BlueBottom = styled.div`
	background-color: #112a84;
`;

const WhiteTitle = styled.div`
	color: white;
	font-size: 16px;
	line-height: 24px;
`;

const WhiteText = styled.div`
	color: white;
	font-size: 13px;
	line-height: 17px;
`;

const FooterCSEMail = styled.a`
	color: white;
	font-size: 13px;
	line-height: 17px;
`;

const ButtonContainer = styled.div`
	text-align: right;
	> button {
		width: 135px;
		font-weight: bold;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: 100%;
		}
	}
`;

const ReCaptchaWithButton = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex-direction: column;
		justify-content: stretch;
	}
`;

const ReCaptchaContainer = styled.div`
	padding-left: 5px;
	padding-bottom: 15px;
`;

const CloseButton = styled.div`
	width: 50;
	height: 50;
	display: "flex";
	justify-content: "end";
	align-content: center;
	cursor: pointer;
`;
