import { authConstants, generalConstants } from "_constants";
import { toast } from "react-toastify";
import { authService } from "services";
import { history } from "_util/_helper";
import { getAuth, signOut, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";

export const authAction = {
	kickout,
	logout,
	loginWithFirebase,
	forgetPassword,
	changePassword,
	getOrganization,
	updateOrganization,
	register,
	resetPassword,
};

function register(form) {
	return (dispatch) => {
		dispatch(request());
		authService.register(form).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: authConstants.type.REGISTER_REQUEST };
	}
	function failure() {
		return { type: authConstants.type.REGISTER_FAIL };
	}
	function success(data) {
		const registerRefNo = data._key;
		return {
			type: authConstants.type.REGISTER_SUCCESS,
			registerRefNo: registerRefNo,
		};
	}
}

function updateOrganization(data) {
	return (dispatch) => {
		dispatch(request());
		authService.updateOrganization(data).then(
			(response) => {
				toast.success("成功更新");
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(fail());
			}
		);
	};

	function request() {
		return {
			type: authConstants.type.EDIT_ORGANIZATION_REQUEST,
		};
	}
	function success(data) {
		return {
			type: authConstants.type.EDIT_ORGANIZATION_SUCCESS,
			data,
		};
	}
	function fail() {
		return {
			type: authConstants.type.EDIT_ORGANIZATION_FAIL,
		};
	}
}

function getOrganization(forceUpdate) {
	return (dispatch) => {
		authService.getOrganization(forceUpdate).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
			}
		);
	};

	function success(user) {
		return {
			type: authConstants.type.FETECH_ORGANIZATION_SUCCESS,
			user,
		};
	}
}

function resetPassword(actionCode, recaptchaValue, password, reset) {
	return async (dispatch) => {
		dispatch(request());
		try {
			await authService.resetPassword({ actionCode, recaptchaValue, password });
			if (reset) {
				toast.success("重設密碼成功");
			} else {
				toast.success("設定密碼成功");
			}
			dispatch(success());
		} catch (error) {
			dispatch(fail(error.error));
		}
	};

	function request() {
		return {
			type: authConstants.type.RESET_PASSWORD_REQUEST,
		};
	}
	function success() {
		return {
			type: authConstants.type.RESET_PASSWORD_SUCCESS,
		};
	}
	function fail(error) {
		return {
			type: authConstants.type.RESET_PASSWORD_FAIL,
			error,
		};
	}
}

function changePassword(firebaseUser, data) {
	return async (dispatch) => {
		dispatch(request());
		try {
			await reauthenticateWithCredential(firebaseUser, EmailAuthProvider.credential(firebaseUser.email, data.password));
			const token = await firebaseUser.getIdToken();
			await authService.changePassword({ token, password: data.password, confirmPassword: data.confirmPassword });
			toast.success("修改密碼成功");
			dispatch(success());
		} catch (error) {
			var errorCode = error.code;
			if (errorCode === "auth/wrong-password") {
				toast.error("提供的現有密碼不正確。");
			} else if (error.error) {
				toast.error(error.error);
			} else {
				toast.error("修改密碼不成功，如有疑問請聯繫管理員。");
			}
			dispatch(fail());
		}
	};

	function request() {
		return {
			type: authConstants.type.CHANGE_PASSWORD_REQUEST,
		};
	}
	function success() {
		return {
			type: authConstants.type.CHANGE_PASSWORD_SUCCESS,
		};
	}
	function fail() {
		return {
			type: authConstants.type.CHANGE_PASSWORD_FAIL,
		};
	}
}

function forgetPassword(data) {
	return (dispatch) => {
		dispatch(request());
		authService.forgetPassword(data).then(
			() => {
				toast.success("已發送重設密碼郵件");
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(fail());
			}
		);
	};

	function request() {
		return {
			type: authConstants.type.FORGET_PASSWORD_REQUEST,
		};
	}
	function success() {
		return {
			type: authConstants.type.FORGET_PASSWORD_SUCCESS,
		};
	}
	function fail() {
		return {
			type: authConstants.type.FORGET_PASSWORD_FAIL,
		};
	}
}

function loginWithFirebase({ token, recaptchaValue, rememberLogin, username }) {
	return async (dispatch) => {
		dispatch(request());
		authService.login({ token, recaptchaValue }).then(
			(response) => {
				dispatch(login(response, { rememberLogin, username }));
			},
			(error) => {
				signOut(getAuth());
				dispatch(fail());
				toast.error(error.error);
			}
		);
	};
	function request() {
		return { type: authConstants.type.LOGIN_REQUEST };
	}
	function fail() {
		return { type: authConstants.type.LOGIN_FAIL };
	}
	function login(user, { rememberLogin, username }) {
		return {
			type: authConstants.type.LOGIN_SUCCESS,
			user,
			data: { rememberLogin, username },
		};
	}
}

function kickout() {
	return async (dispatch) => {
		signOut(getAuth());
		dispatch(kick());
		history.push(generalConstants.PATH.LOGIN);
		toast.error("連接逾時，請重新登入");
	};

	function kick() {
		return { type: authConstants.type.UNAUTHORIZED_ERROR };
	}
}

function logout() {
	return (dispatch) => {
		dispatch(request());
		authService.logout().then(
			async (response) => {
				signOut(getAuth());
				dispatch(logout());
				window.localStorage.clear();
				history.push(generalConstants.PATH.LOGIN);
				toast.success("用戶已登出");
			},
			(error) => {
				dispatch(fail());
				toast.error(error.error);
			}
		);
	};
	function request() {
		return { type: authConstants.type.LOGOUT_REQUEST };
	}
	function fail() {
		return { type: authConstants.type.LOGOUT_FAIL };
	}
	function logout() {
		return { type: authConstants.type.LOGOUT };
	}
}
