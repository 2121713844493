import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import CloseIcon from "@material-ui/icons/Close";
import { DialogContent } from "@material-ui/core";
import { CancelButton, YellowButton } from "components/general";
import { MOBILE_MAX_WIDTH } from "_constants";
import styled from "styled-components";
import _ from "lodash";
import ReCAPTCHA from "react-google-recaptcha";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

export const EmailExpiredDialog = (props) => {
	const { onClose, popupDetail, handleResend } = props;
	const recaptchaRef = useRef(null);
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	const onSubmit = async () => {
		if (!debouncer.token()) {
			return;
		}
		const reacptchaValue = await recaptchaRef.current.executeAsync();
		recaptchaRef.current.reset();
		handleResend(reacptchaValue);
	};

	return (
		<ReactModal isOpen={!_.isEmpty(popupDetail.message)}>
			<DialogContent style={{ paddingBottom: 20, paddingRight: 0, paddingTop: 0 }}>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
					<ForgetPasswordTitle style={{ paddingTop: 20 }}>{popupDetail.title}</ForgetPasswordTitle>
					<CloseButton onClick={onClose}>
						<CloseIcon style={{ marginRight: 15, marginTop: 15 }} />
					</CloseButton>
				</div>
				<SubTitle>{popupDetail.message}</SubTitle>
			</DialogContent>
			<ButtonContainer>
				<ReCaptchaWithButton>
					<ReCaptchaContainer>
						<ReCAPTCHA badge={"inline"} ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} size={"invisible"} hl="zh-HK" />
					</ReCaptchaContainer>
					<ButtonContainer>
						<YellowButton handleOnClick={onSubmit} style={{ marginRight: "15px", marginTop: "15px" }} label={"提交"}></YellowButton>
						<CancelButton label={"取消"} style={{ marginRight: "15px", marginTop: "15px" }} handleOnClick={onClose} />
					</ButtonContainer>
				</ReCaptchaWithButton>
			</ButtonContainer>
		</ReactModal>
	);
};

EmailExpiredDialog.propTypes = {
	onClose: PropTypes.func,
	popupDetail: PropTypes.object,
	handleResend: PropTypes.func,
};

const ForgetPasswordTitle = styled.div`
	color: #112a84;
	font-size: 36px;
	font-weight: 900;
	line-height: 1.5;
`;

const SubTitle = styled.div`
	color: #333333;
	font-size: 16px;
	line-height: 20px;
`;

const ButtonContainer = styled.div`
	text-align: right;
	> button {
		width: 135px;
		font-weight: bold;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: 100%;
		}
	}
`;

const CloseButton = styled.div`
	width: 50;
	height: 50;
	display: "flex";
	justify-content: "end";
	align-content: center;
	cursor: pointer;
`;

const ReCaptchaWithButton = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex-direction: column;
		justify-content: stretch;
	}
`;

const ReCaptchaContainer = styled.div`
	padding-left: 5px;
	padding-bottom: 15px;
`;
