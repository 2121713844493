export const learningCentreConstants = {
	type: {
		RESET_LEARNING_CENTRE_SUCCESS: "RESET_LEARNING_CENTRE_SUCCESS",

		CREATE_LEARNING_CENTRE_REQUEST: "CREATE_LEARNING_CENTRE_REQUEST",
		CREATE_LEARNING_CENTRE_SUCCESS: "CREATE_LEARNING_CENTRE_SUCCESS",
		CREATE_LEARNING_CENTRE_FAILURE: "CREATE_LEARNING_CENTRE_FAILURE",

		UPDATE_LEARNING_CENTRE_REQUEST: "UPDATE_LEARNING_CENTRE_REQUEST",
		UPDATE_LEARNING_CENTRE_SUCCESS: "UPDATE_LEARNING_CENTRE_SUCCESS",
		UPDATE_LEARNING_CENTRE_FAILURE: "UPDATE_LEARNING_CENTRE_FAILURE",

		DELETE_LEARNING_CENTRE_REQUEST: "DELETE_LEARNING_CENTRE_REQUEST",
		DELETE_LEARNING_CENTRE_SUCCESS: "DELETE_LEARNING_CENTRE_SUCCESS",
		DELETE_LEARNING_CENTRE_FAILURE: "DELETE_LEARNING_CENTRE_FAILURE",

		FETCH_LEARNING_CENTRE_LIST_REQUEST: "FETCH_LEARNING_CENTRE_LIST_REQUEST",
		FETCH_LEARNING_CENTRE_LIST_SUCCESS: "FETCH_LEARNING_CENTRE_LIST_SUCCESS",
		FETCH_LEARNING_CENTRE_LIST_FAILURE: "FETCH_LEARNING_CENTRE_LIST_FAILURE",

		FETCH_CENTRE_REQUEST: "FETCH_CENTRE_REQUEST",
		FETCH_CENTRE_SUCCESS: "FETCH_CENTRE_SUCCESS",
		FETCH_CENTRE_FAILURE: "FETCH_CENTRE_FAILURE",
	},
	TABLE_COLUMN: [
		{ key: "name", value: "補習社名稱", searchable: false },
		{ key: "district", value: "地區", searchable: true },
		{ key: "courseNum", value: "課程數量", searchable: false },
		{ key: "category", value: "課程類別", searchable: false },
		{ key: "lastUpdateTime", value: "最後更新時間", searchable: false },
	],
	DATA: {
		status: {
			ACTIVE: { key: "ACTIVE", label: "正常", type: "SUCCESS" },
			CLOSED: { key: "CLOSED", label: "已停辦", type: "FAILURE" },
		},
		socialStatus: {
			VERIFIED: { key: "VERIFIED", label: "已驗証", type: "SUCCESS" },
			UNVERIFIED: { key: "UNVERIFIED", label: "未驗証", type: "PENDING" },
			NONE: { key: "NONE", label: "未加入", type: "FAILURE" },
		},
		sex: {
			MALE: { key: "male", label: "男" },
			FEMALE: { key: "female", label: "女" },
		},
		accountType: {
			PERSONAL: { key: "PERSONAL", label: "個人" },
			CORPORATE: { key: "CORPORATE", label: "機構" },
		},
		privateStatus: {
			PRIVATE: { key: "PRIVATE", label: "不公開" },
			PUBLIC: { key: "PUBLIC", label: "公開" },
		},
		district: {
			"Tai Koo": { label: "太古" },
			"Western District": { label: "西環" },
			"Shek Tong Tsui": { label: "石塘咀" },
			"Sai Ying Pun": { label: "西營盤" },
			"Kennedy Town": { label: "堅尼地城" },
			"Sheung Wan": { label: "上環" },
			Central: { label: "中環" },
			"Mid-Levels": { label: "半山" },
			Admiralty: { label: "金鐘" },
			"Wan Chai": { label: "灣仔" },
			"Happy Valley": { label: "跑馬地" },
			"Causeway Bay": { label: "銅鑼灣" },
			"Tin Hau": { label: "天后" },
			"North Point": { label: "北角" },
			"Quarry Bay": { label: "鰂魚涌" },
			"Sai Wan Ho": { label: "西灣河" },
			"Shau Kei Wan": { label: "筲箕灣" },
			"Heng Fa Chuen": { label: "杏花邨" },
			"Chai Wan": { label: "柴灣" },
			"Pok Fu Lam": { label: "薄扶林" },
			Aberdeen: { label: "香港仔" },
			"Ap Lei Chau": { label: "鴨脷洲" },
			"Wong Chuk Hang": { label: "黃竹坑" },
			"Repulse Bay": { label: "淺水灣" },
			Stanley: { label: "赤柱" },

			"Tai Kok Tsui and Olympic": { label: "大角咀及奧運" },
			"Mei Foo": { label: "美孚" },
			"Lai Chi Kok": { label: "荔枝角" },
			"Cheung Sha Wan": { label: "長沙灣" },
			"Sham Shui Po": { label: "深水埗" },
			"Prince Edward": { label: "太子" },
			"Mong Kok": { label: "旺角" },
			"Yau Ma Tei": { label: "油麻地" },
			Jordan: { label: "佐敦" },
			"Tsim Sha Tsui": { label: "尖沙咀" },
			"Hung Hom": { label: "紅磡" },
			"Ho Man Tin": { label: "何文田" },
			"To Kwa Wan": { label: "土瓜灣" },
			"Shek Kip Mei": { label: "石硤尾" },
			"Kowloon Tong": { label: "九龍塘" },
			"Kowloon City": { label: "九龍城" },
			"Lok Fu": { label: "樂富" },
			"Wong Tai Sin": { label: "黃大仙" },
			"San Po Kong": { label: "新蒲崗" },
			"Diamond Hill": { label: "鑽石山" },
			"Tsz Wan Shan": { label: "慈雲山" },
			"Choi Hung": { label: "彩虹" },
			"Kowloon Bay": { label: "九龍灣" },
			"Ngau Tau Kok": { label: "牛頭角" },
			"Kwun Tong": { label: "觀塘" },
			"Lam Tin": { label: "藍田" },
			"Yau Tong": { label: "油塘" },

			"Tai Wai": { label: "大圍" },
			"Sha Tin": { label: "沙田" },
			"Fo Tan": { label: "火炭" },
			"Ma On Shan": { label: "馬鞍山" },
			"Tai Po": { label: "大埔" },
			Fanling: { label: "粉嶺" },
			"Sheung Shui": { label: "上水" },
			"Kwai Fong": { label: "葵芳" },
			"Kwai Chung": { label: "葵涌" },
			"Tsuen Wan": { label: "荃灣" },
			"Tsing Yi": { label: "青衣" },
			"Ma Wan": { label: "馬灣" },
			"Sham Tseng": { label: "深井" },
			"Tuen Mun": { label: "屯門" },
			"Yuen Long": { label: "元朗" },
			"Tin Shui Wai": { label: "天水圍" },
			"Sai Kung": { label: "西貢" },
			"Tseung Kwan O": { label: "將軍澳" },

			"Discovery Bay": { label: "愉景灣" },
			"Tung Chung": { label: "東涌" },
			"Lantau Island": { label: "大嶼山" },
			"Tai O": { label: "大澳" },
			"Cheung Chau": { label: "長洲" },
			"Peng Chau": { label: "坪洲" },
			"Lamma Island": { label: "南丫島" },
		},
	},
	OPTION: {
		district: [
			{ key: "Tai Koo", value: "太古" },
			{ key: "Western District", value: "西環" },
			{ key: "Shek Tong Tsui", value: "石塘咀" },
			{ key: "Sai Ying Pun", value: "西營盤" },
			{ key: "Kennedy Town", value: "堅尼地城" },
			{ key: "Sheung Wan", value: "上環" },
			{ key: "Central", value: "中環" },
			{ key: "Mid-Levels", value: "半山" },
			{ key: "Admiralty", value: "金鐘" },
			{ key: "Wan Chai", value: "灣仔" },
			{ key: "Happy Valley", value: "跑馬地" },
			{ key: "Causeway Bay", value: "銅鑼灣" },
			{ key: "Tin Hau", value: "天后" },
			{ key: "North Point", value: "北角" },
			{ key: "Quarry Bay", value: "鰂魚涌" },
			{ key: "Sai Wan Ho", value: "西灣河" },
			{ key: "Shau Kei Wan", value: "筲箕灣" },
			{ key: "Heng Fa Chuen", value: "杏花邨" },
			{ key: "Chai Wan", value: "柴灣" },
			{ key: "Pok Fu Lam", value: "薄扶林" },
			{ key: "Aberdeen", value: "香港仔" },
			{ key: "Ap Lei Chau", value: "鴨脷洲" },
			{ key: "Wong Chuk Hang", value: "黃竹坑" },
			{ key: "Repulse Bay", value: "淺水灣" },
			{ key: "Stanley", value: "赤柱" },

			{ key: "Tai Kok Tsui and Olympic", value: "大角咀及奧運" },
			{ key: "Mei Foo", value: "美孚" },
			{ key: "Lai Chi Kok", value: "荔枝角" },
			{ key: "Cheung Sha Wan", value: "長沙灣" },
			{ key: "Sham Shui Po", value: "深水埗" },
			{ key: "Prince Edward", value: "太子" },
			{ key: "Mong Kok", value: "旺角" },
			{ key: "Yau Ma Tei", value: "油麻地" },
			{ key: "Jordan", value: "佐敦" },
			{ key: "Tsim Sha Tsui", value: "尖沙咀" },
			{ key: "Hung Hom", value: "紅磡" },
			{ key: "Ho Man Tin", value: "何文田" },
			{ key: "To Kwa Wan", value: "土瓜灣" },
			{ key: "Shek Kip Mei", value: "石硤尾" },
			{ key: "Kowloon Tong", value: "九龍塘" },
			{ key: "Kowloon City", value: "九龍城" },
			{ key: "Lok Fu", value: "樂富" },
			{ key: "Wong Tai Sin", value: "黃大仙" },
			{ key: "San Po Kong", value: "新蒲崗" },
			{ key: "Diamond Hill", value: "鑽石山" },
			{ key: "Tsz Wan Shan", value: "慈雲山" },
			{ key: "Choi Hung", value: "彩虹" },
			{ key: "Kowloon Bay", value: "九龍灣" },
			{ key: "Ngau Tau Kok", value: "牛頭角" },
			{ key: "Kwun Tong", value: "觀塘" },
			{ key: "Lam Tin", value: "藍田" },
			{ key: "Yau Tong", value: "油塘" },

			{ key: "Tai Wai", value: "大圍" },
			{ key: "Sha Tin", value: "沙田" },
			{ key: "Fo Tan", value: "火炭" },
			{ key: "Ma On Shan", value: "馬鞍山" },
			{ key: "Tai Po", value: "大埔" },
			{ key: "Fanling", value: "粉嶺" },
			{ key: "Sheung Shui", value: "上水" },
			{ key: "Kwai Fong", value: "葵芳" },
			{ key: "Kwai Chung", value: "葵涌" },
			{ key: "Tsuen Wan", value: "荃灣" },
			{ key: "Tsing Yi", value: "青衣" },
			{ key: "Ma Wan", value: "馬灣" },
			{ key: "Sham Tseng", value: "深井" },
			{ key: "Tuen Mun", value: "屯門" },
			{ key: "Yuen Long", value: "元朗" },
			{ key: "Tin Shui Wai", value: "天水圍" },
			{ key: "Sai Kung", value: "西貢" },
			{ key: "Tseung Kwan O", value: "將軍澳" },

			{ key: "Discovery Bay", value: "愉景灣" },
			{ key: "Tung Chung", value: "東涌" },
			{ key: "Lantau Island", value: "大嶼山" },
			{ key: "Tai O", value: "大澳" },
			{ key: "Cheung Chau", value: "長洲" },
			{ key: "Peng Chau", value: "坪洲" },
			{ key: "Lamma Island", value: "南丫島" },
		],
		category: [
			{ key: "節日活動", value: "節日活動" },
			{ key: "中文", value: "中文" },
			{ key: "英語", value: "英語" },
			{ key: "數學", value: "數學" },
			{ key: "普通話", value: "普通話" },
			{ key: "外語", value: "外語" },
			{ key: "常識", value: "常識" },
			{ key: "電腦", value: "電腦" },
			{ key: "科學", value: "科學" },
			{ key: "STEM", value: "STEM" },
			{ key: "STEAM", value: "STEAM" },
			{ key: "運動", value: "運動" },
			{ key: "舞蹈", value: "舞蹈" },
			{ key: "武術", value: "武術" },
			{ key: "音樂", value: "音樂" },
			{ key: "美術", value: "美術" },
			{ key: "棋藝", value: "棋藝" },
			{ key: "水上活動", value: "水上活動" },
			{ key: "戶外活動", value: "戶外活動" },
			{ key: "網上課程", value: "網上課程" },
			{ key: "面試課程", value: "面試課程" },
			{ key: "Playgroup", value: "Playgroup" },
			{ key: "IB課程", value: "IB課程" },
			{ key: "常規課程", value: "常規課程" },
			{ key: "功課輔導", value: "功課輔導" },
			{ key: "考試準備", value: "考試準備" },
			{ key: "全能發展", value: "全能發展" },
			{ key: "親子體驗", value: "親子體驗" },
			{ key: "嬰幼兒教育", value: "嬰幼兒教育" },
		],
		status: [
			{ key: "ACTIVE", value: "正常" },
			{ key: "CLOSED", value: "已停辦" },
		],
	},
};
