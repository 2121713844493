import React from "react";
import PropTypes from "prop-types";
//import { useDispatch } from "react-redux";
import styled from "styled-components";

import { Link } from "react-router-dom";

import { generalConstants } from "_constants";
//import { authAction } from "actions";

import { history } from "_util/_helper";
//import _ from "lodash";
import headerLogo from "resources/img/header_logo@2x.png";
//import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as CourseIcon } from "resources/img/course_icon.svg";
import { ReactComponent as LearningCenterIcon } from "resources/img/learningCentre_icon.svg";

//advertising

export const AdminDesktopMenubar = (props) => {
	const { section } = props;

	//const dispatch = useDispatch();

	const getIcon = (session) => {
		switch (session) {
			case generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key:
				return <LearningCenterIcon />;
			case generalConstants.NAV_TAB.LEARNING_COURSE_MANAGEMENT.key:
				return <CourseIcon />;
			default:
				return null;
		}
	};

	const renderNavItem = Object.keys(generalConstants.NAV_TAB).map((item) => {
		if (generalConstants.NAV_TAB[item].show) {
			return (
				<Link key={item} style={{ textDecoration: "none" }} to={generalConstants.PATH[item]}>
					<NavItem key={item} current={item === section}>
						<NavIcon>{getIcon(generalConstants.NAV_TAB[item].key)}</NavIcon>
						{generalConstants.TAB_NAME[item]}
					</NavItem>
				</Link>
			);
		}
		return null;
	});

	return (
		<Container>
			<IconBox>
				<Logo src={headerLogo}></Logo>
			</IconBox>

			<NavLayer>
				<InnerContainer>
					<RowInBlock>
						<NavItem />
						{renderNavItem}
					</RowInBlock>
				</InnerContainer>
			</NavLayer>
		</Container>
	);
};

AdminDesktopMenubar.propTypes = {
	section: PropTypes.string,
	label: PropTypes.string,
	sublabel: PropTypes.string,
	buttonLabel: PropTypes.string,
	handleOnClick: PropTypes.func,
};

const Logo = styled.img`
	max-width: 100px;
	max-height: 100px;
	width: 100%;
	height: 100%;
	padding: 20px 12px 20px 12px;
`;
const Container = styled.div`
	padding-bottom: 10px;
	background-color: #ffffff;
	height: 100%;
`;

const NavLayer = styled.div`
	width: 100%;
	background-color: #ffffff;
`;

const IconBox = styled.div`
	width: 100%;
	/*box-shadow: 0 6px 4px 0 rgba(0, 0, 0, 0.16);*/
	background-color: white;
	text-align: center;
	background-image: linear-gradient(177deg, #ffffff 96%, #333399 108%);
`;

const NavIcon = styled.div`
	fill: #cccccc;
	width: 24px;
	height: 18px;
	display: inline-block;
	text-align: center;
	padding-right: 6px;

	&:hover {
		color: #666666;
	}
`;

const InnerContainer = styled.div`
	height: 100%;
	width: 100%;
	margin: auto;
`;

const RowInBlock = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`;
const NavItem = styled.div`
	padding: 13px 20px;
	text-align: center;
	font-size: 12px;
	color: ${(props) => (props.current ? "#666666" : "#bababa")};

	font-weight: bold;
	display: flex;
	cursor: pointer;

	font-stretch: normal;
	font-style: normal;
	line-height: 1.83;
	letter-spacing: normal;
	text-align: left;
	align-items: center;

	&:hover {
		color: #666666;
	}
	& svg {
		fill: ${(props) => (props.current ? "#666666" : "#bababa")};
	}
	&:hover svg {
		fill: #666666;
	}
`;

/*
const FooterLayer = styled.div`
	background-color: #ffffff;
	padding:5px 0px 30px 0px;
	margin:100px 27px 0 27px; flex: 1 0 0%;
	border-top: #dddddd 1px solid;
`;



const FooterItem = styled.a`
	padding:2.5px 0;
	text-align: center;
	width: 100%;
	font-size: 11px;
	color: ${(props) => (props.current ? "#333333" : "#666666")};
	font-weight: bold;
	display: flex;
	cursor: pointer;
    
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
	text-align: left;
	text-decoration: none;
   
	&:hover {
		color: #333333;
	}
`;
*/
