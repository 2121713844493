import styled from "styled-components";


export const SearchBar = styled.div`
    display: flex;
    padding:0 10px; 
    flex-direction: row;
`;

export const SearchBarLeft = styled.div`
	flex: 1;
	min-height: 30px;
`;

export const SearchBarRight = styled.div`
	flex: 1;
	padding-left: 20px;
`;