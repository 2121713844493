import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const CategoryButton = (props) => {
	const { label, handleOnClick, style, buttonIcon } = props;

	return (
		<div
			variant="contained"
			style={{
				minWidth: "40px",
				minHeight: "26px",
				backgroundColor: "#333399",
				borderColor: "#333399",
				borderRadius: 20,
				...style,
			}}
			onClick={() => {
				if (!!handleOnClick) {
					handleOnClick();
				}
			}}
		>
			<BlockGroup
				style={{
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					display: "flex",
				}}
			>
				<div
					style={{
						padding: buttonIcon ? "0px 0px 2px 12px" : "4px 12px",
						fontSize: "12px",
						whiteSpace: "nowrap",
						textAlign: "center",
						color: "white",
						fill: "white",
					}}
				>
					{label}
				</div>
				<div>{buttonIcon}</div>
			</BlockGroup>
		</div>
	);
};

CategoryButton.propTypes = {
	label: PropTypes.string,
	handleOnClick: PropTypes.func,
	style: PropTypes.object,
	buttonIcon: PropTypes.object,
};

CategoryButton.defaultProps = {};

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;
