import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextField, YellowButton } from "components/general";
import { toast } from "react-toastify";
import { useKey } from "_util/hook";
import headerLogo from "resources/img/header_logo@2x.png";
import titleImg from "resources/img/title.png";
import registerButtonImgWeb from "resources/img/register_button_web.png";
import registerButtonImgMobile from "resources/img/register_button_mobile.png";
import GraduationHatImgWeb from "resources/img/graduation_hat_web.png";
import GraduationHatImgMobile from "resources/img/graduation_hat_mobile.png";
import { MOBILE_MAX_WIDTH, DESKTOP_MIN_WIDTH } from "_constants";
import Checkbox from "@material-ui/core/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";
import { EmailVerifyRequestForm } from "components/auth";
import { authService } from "services";
import { onAuthStateChanged, getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { authAction } from "actions";

export const AuthContainer = (props) => {
	const dispatch = useDispatch();

	const { showForgetPassword, handleForgetPassword, isSubmitting, setSubmitting } = props;
	const signInEmail = useSelector(({ auth }) => auth.signInEmail);
	const recaptchaRef = useRef(null);
	const isLoginFetching = useSelector(({ auth }) => auth.isLoginFetching);
	const loginFailure = useSelector(({ auth }) => auth.loginFailure);
	const loggedIn = useSelector(({ auth }) => auth.loggedIn);
	const [formData, setFormData] = useState({ username: "", password: "", rememberLogin: true });
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));
	const [showVerifyEmailForm, setShowVerifyEmailForm] = useState(false);

	const enterPress = useKey("enter");
	const rememberLoginRef = useRef();
	const emailRef = useRef();
	const recaptchaValueRef = useRef();

	const handleSendVerifyEmail = async (input) => {
		try {
			await authService.resendEmail({ ...input, email: formData.username });
			toast.success("成功重發電子郵件");
			setShowVerifyEmailForm(false);
			signOut(getAuth());
		} catch (err) {
			if (err.error) {
				toast.error(err.error);
			} else {
				toast.error(`請稍後再試`);
			}
		}
	};

	const handleOnClick = async () => {
		if (!formData.username) {
			toast.error("請填寫電郵");
			return;
		}
		if (!formData.password) {
			toast.error("請填寫密碼");
			return;
		}
		if (!debouncer.token()) {
			return;
		}
		try {
			recaptchaValueRef.current = await recaptchaRef.current.executeAsync();
			recaptchaRef.current.reset();
			emailRef.current = formData.username;
			rememberLoginRef.current = formData.rememberLogin;
			await signInWithEmailAndPassword(getAuth(), formData.username, formData.password);
		} catch (error) {
			var errorCode = error.code;
			if (errorCode === "auth/invalid-email" || errorCode === "auth/user-not-found" || errorCode === "auth/wrong-password") {
				toast.error("提供的電子郵件或者密碼不正確。");
			} else if (errorCode === "auth/user-disabled") {
				toast.error("賬號已被暫停使用，如有疑問請聯繫管理員。");
			} else {
				toast.error("無法連接到服務器");
			}
			setSubmitting(false);
		}
	};

	const backendLogin = async (token) => {
		if (!loggedIn) {
			setSubmitting(true);
			dispatch(
				authAction.loginWithFirebase({
					token,
					recaptchaValue: recaptchaValueRef.current,
					rememberLogin: rememberLoginRef.current,
					username: emailRef.current,
				})
			);
		}
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
			if (user && user.emailVerified) {
				const token = await user.getIdToken();
				backendLogin(token);
			} else if (user && !user.emailVerified) {
				setShowVerifyEmailForm(true);
			}
		});

		return unsubscribe;
	}, []);

	useEffect(() => {
		if (loginFailure) {
			setSubmitting(false);
		}
	}, [loginFailure]);

	useEffect(() => {
		if (enterPress && !showForgetPassword) {
			handleOnClick();
		}
	}, [enterPress]);

	useEffect(() => {
		if (signInEmail) {
			setFormData({ ...formData, username: signInEmail });
		}
	}, [signInEmail]);

	const handleOnChangeCheckbox = (obj) => {
		setFormData({ ...formData, rememberLogin: obj.target.checked });
	};

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
	};

	const handleCloseEmailVerifyRequestForm = () => {
		setShowVerifyEmailForm(false);
		signOut(getAuth());
	};

	return (
		<Container>
			<LeftContainer>
				<LogoPart>
					<Logo src={headerLogo}></Logo>
				</LogoPart>
				<TextPart>
					<WelcomeTitleText>歡迎!</WelcomeTitleText>
					<WelcomeText>
						進入
						<TitleLogo src={titleImg} />
					</WelcomeText>
					<WelcomeText>教育中心登入頁面</WelcomeText>
					<Link style={{ textDecoration: "none" }} to={`/register`}>
						<RegistrationButton />
					</Link>
				</TextPart>
			</LeftContainer>
			<RightContainer>
				<GraduationHat>
					<GraduationHatImg />
				</GraduationHat>
				<RightInnerContainer>
					<TitleText>教育中心登入</TitleText>
					<div>
						<TextFieldLabel>電郵</TextFieldLabel>
						<TextField
							placeholder="電郵"
							style={{ backgroundColor: "white" }}
							value={formData.username}
							name="username"
							handleChange={handleChange}
							disabled={isSubmitting}
						/>
						<div style={{ marginBottom: "10px" }} />
						<TextFieldLabel>密碼</TextFieldLabel>
						<TextField
							placeholder="密碼"
							style={{ backgroundColor: "white" }}
							value={formData.password}
							name="password"
							handleChange={handleChange}
							type="password"
							disabled={isSubmitting}
						/>
					</div>
					<ForgetPassword>
						<Checkbox
							style={{ color: "white", caretColor: "white", paddingLeft: "0", paddingTop: 0, paddingRight: 0 }}
							checked={formData.rememberLogin}
							onChange={handleOnChangeCheckbox}
						></Checkbox>
						<RememberLoginText>記住電郵</RememberLoginText>
						<EmptyText></EmptyText>
						<ForgetPasswordText onClick={handleForgetPassword}>忘記密碼?</ForgetPasswordText>
					</ForgetPassword>
					<div style={{ flex: 30 }}></div>
					<ReCaptchaWithButton>
						<ReCaptchaContainer>
							<ReCAPTCHA
								badge={"inline"}
								ref={recaptchaRef}
								sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
								size={"invisible"}
								hl="zh-HK"
								onChange={() => {
									setSubmitting(true);
								}}
							/>
						</ReCaptchaContainer>
						<ButtonContainer>
							<YellowButton label={"登入"} handleOnClick={handleOnClick} active={!isLoginFetching} disabled={isLoginFetching} />
						</ButtonContainer>
					</ReCaptchaWithButton>
				</RightInnerContainer>
			</RightContainer>
			<BlankBlock>&nbsp;</BlankBlock>
			<EmailVerifyRequestForm
				open={showVerifyEmailForm}
				onClose={handleCloseEmailVerifyRequestForm}
				handleSendVerifyEmail={handleSendVerifyEmail}
			/>
		</Container>
	);
};
AuthContainer.propTypes = {
	handleLogin: PropTypes.func,
	handleForgetPassword: PropTypes.func,
	showForgetPassword: PropTypes.bool,
};

const Container = styled.div`
	height: 60%;
	width: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	display: flex;
	flex-direction: row;
	filter: drop-shadow(0px 0px 20px rgba(0, 0, 0));
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		width: calc(100% - 30px);
		flex-direction: column;
	}
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		min-width: 720px;
		min-height: 450px;
	}
`;

const LeftContainer = styled.div`
	display: flex;
	flex: 2;
	flex-direction: column;
	background-color: white;
	padding-right: 25px;
	padding-left: 25px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex-direction: row;
		padding-top: 25px;
	}
`;

const LogoPart = styled.div`
	max-width: 100px;
	max-height: 100px;
	width: 100%;
	height: 100%;
	padding-bottom: 28px;
`;

const TextPart = styled.div`
	border-top: #dbdbdb 1px solid;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		border-top: 0;
		border-left: #dbdbdb 1px solid;
		padding-left: 15px;
		margin-left: 15px;
	}
`;

const Logo = styled.img`
	max-width: 100px;
	max-height: 100px;
	width: 100%;
	height: 100%;
	padding-left: 2px;
	padding-top: 24px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		max-width: 80px;
		max-height: 80px;
	}
`;

const TitleLogo = styled.img`
	padding-top: 8px;
	width: 130px;
	height: 24px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		width: 94px;
		height: 17px;
	}
`;

const RightContainer = styled.div`
	flex: 3;
	background-color: #112a84;
	border-top: #baa191 6px solid;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex: 4;
		padding-top: 15px;
		padding-bottom: 15px;
	}
`;

const RightInnerContainer = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;
	height: 90%;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		width: 88%;
		height: auto;
	}
`;

const GraduationHat = styled.div`
	background-color: #baa191;
	padding: 5px 10px;
	position: absolute;
	right: 0;
	top: -1px;
	z-index: 2;
`;

const GraduationHatImg = styled.div`
	background-image: url(${GraduationHatImgWeb});
	width: 66px;
	height: 48px;
	background-size: cover;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		background-image: url(${GraduationHatImgMobile});
	}
`;

const TitleText = styled.div`
	color: white;
	font-size: 36px;
	line-height: 1.5;
	font-weight: bold;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 24px;
		line-height: 2.1;
	}
`;

const WelcomeTitleText = styled.div`
	color: #1f2e64;
	font-size: 46px;
	line-height: 1.5;
	font-weight: bold;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 30px;
	}
`;

const WelcomeText = styled.div`
	display: flex;
	flex-direction: row;
	color: #1f2e84;
	font-size: 26px;
	font-weight: bold;
	margin-bottom: 5px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 20px;
	}
`;

const RegistrationButton = styled.div`
	background-image: url(${registerButtonImgWeb});
	width: 163px;
	height: 50px;
	background-size: cover;
	cursor: pointer;
	&:hover {
		opacity: 0.6;
	}
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		background-image: url(${registerButtonImgMobile});
		width: 103px;
		height: 31px;
	}
`;

const TextFieldLabel = styled.div`
	color: white;
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 3px;
`;

const ForgetPassword = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 10px;
`;
const RememberLoginText = styled.span`
	color: white;
	font-size: 14px;
`;

const EmptyText = styled.span`
	color: white;
	font-size: 14px;
	line-height: 1.5;
	flex: 2;
	width: 100%;
`;

const ForgetPasswordText = styled.span`
	color: white;
	font-size: 14px;
	line-height: 1.5;
	cursor: pointer;
	text-decoration: underline;
`;

const ButtonContainer = styled.div`
	padding: 15px 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	> button {
		width: 135px;
		font-weight: bold;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: 100%;
		}
	}
`;

const ReCaptchaWithButton = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex-direction: column;
		justify-content: stretch;
	}
`;

const ReCaptchaContainer = styled.div`
	padding-bottom: 10px;
	background-color: "red";
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		padding-bottom: 0px;
	}
`;

const BlankBlock = styled.div`
	flex: 20;
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		display: none;
	}
`;
