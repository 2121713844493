import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Link } from "react-router-dom";

import { generalConstants } from "_constants";

import headerLogo from "resources/img/logo512.png";
import NavigationImg from "resources/img/navigation.svg";

import { ReactComponent as CourseIcon } from "resources/img/course_icon.svg";
import { ReactComponent as LearningCenterIcon } from "resources/img/learningCentre_icon.svg";
import { AdminUserInfo } from "components/admin";

export const AdminMobileMenubar = (props) => {
	const { section } = props;

	const [active, setActive] = useState(false);

	const getIcon = (session) => {
		switch (session) {
			case generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key:
				return <LearningCenterIcon />;
			case generalConstants.NAV_TAB.LEARNING_COURSE_MANAGEMENT.key:
				return <CourseIcon />;
			default:
				return null;
		}
	};

	const renderNavItem = Object.keys(generalConstants.NAV_TAB).map((item) => {
		if (generalConstants.NAV_TAB[item].show) {
			return (
				<Link key={item} style={{ textDecoration: "none" }} to={generalConstants.PATH[item]}>
					<NavItem key={item} current={item === section}>
						<NavIcon>{getIcon(generalConstants.NAV_TAB[item].key)}</NavIcon>
						{generalConstants.TAB_NAME[item]}
					</NavItem>
				</Link>
			);
		}
		return null;
	});

	return (
		<Container>
			<Navigation onClick={() => setActive(!active)}>
				<NavigationIcon src={NavigationImg}></NavigationIcon>
			</Navigation>
			<IconBox>
				<Logo src={headerLogo}></Logo>
			</IconBox>
			<AdminUserInfo />
			<NavMask active={active} onClick={() => setActive(false)} />
			<NavLayer active={active}>
				<InnerContainer>
					<RowInBlock>
						<NavItem />
						{renderNavItem}
					</RowInBlock>
				</InnerContainer>
			</NavLayer>
		</Container>
	);
};

AdminMobileMenubar.propTypes = {
	section: PropTypes.string,
	label: PropTypes.string,
	sublabel: PropTypes.string,
	buttonLabel: PropTypes.string,
	handleOnClick: PropTypes.func,
};

const Logo = styled.img`
	max-width: 50px;
	max-height: 50px;
	height: 100%;
	padding: 9px 9px 9px 9px;
`;

const Navigation = styled.div`
	flex: 0 0 33.3%;
`;

const NavigationIcon = styled.img`
	padding: 30px 18px 20px 18px;
`;
const Container = styled.div`
	background-color: #ffffff;
	height: 100%;
	display: flex;
`;

const NavMask = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 71px;
	bottom: 0;
	background-color: white;
	z-index: 199;
	transition: all 0.1s;

	opacity: ${(props) => (props.active === true ? "0.3" : "0")};
	pointer-events: ${(props) => (props.active === true ? "" : "none")};
	user-select: ${(props) => (props.active === true ? "" : "none")};
`;

const NavLayer = styled.div`
	background-color: #ffffff;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	position: absolute;
	top: 71px;
	right: 0;
	height: 100%;
	transition: all 0.3s;
	overflow: hidden;
	width: ${(props) => (props.active === true ? "30%" : "0")};
	z-index: 200;
`;

const IconBox = styled.div`
	flex: 0 0 33.3%;
	background-color: white;
	text-align: center;
`;

const NavIcon = styled.div`
	fill: #cccccc;
	width: 24px;
	height: 18px;
	display: inline-block;
	text-align: center;
	padding-right: 6px;

	&:hover {
		color: #666666;
	}
`;

const InnerContainer = styled.div`
	height: 100%;
	width: 100%;
	margin: auto;
`;

const RowInBlock = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
`;
const NavItem = styled.div`
	padding: 13px 20px;
	white-space: nowrap;
	text-align: center;
	font-size: 12px;
	color: ${(props) => (props.current ? "#666666" : "#bababa")};

	font-weight: bold;
	display: flex;
	cursor: pointer;

	font-stretch: normal;
	font-style: normal;
	line-height: 1.83;
	letter-spacing: normal;
	text-align: left;
	align-items: center;

	&:hover {
		color: #666666;
	}
	& svg {
		fill: ${(props) => (props.current ? "#666666" : "#bababa")};
	}
	&:hover svg {
		fill: #666666;
	}
`;
