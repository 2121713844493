import React from "react";
import  { MatchMedia } from "components/responsive";
import PropTypes from "prop-types";
import { DESKTOP_MIN_WIDTH } from "_constants";


export const QUERY = `(min-width: ${DESKTOP_MIN_WIDTH})`;

export const Desktop = (props) => {
  const { children } = props;
  return <MatchMedia query={QUERY}>{children}</MatchMedia>;
}

export function desktop() {
  const match = window.matchMedia(QUERY);
  return match.matches;
}

Desktop.propTypes = {
  children: PropTypes.any
};


Desktop.defaultProps = {
	children: undefined
};

