import { useState, useEffect } from "react";

export function useKey(key) {
	const [pressed, setPressed] = useState(false);

	const match = (event) => key && event.key && key.toLowerCase() === event.key.toLowerCase();

	const onDown = (event) => {
		if (match(event)) {
			setPressed(true);
			setTimeout(() => {
				//for reset press action detection
				setPressed(false);
			}, 500);
		}
	};

	useEffect(() => {
		window.addEventListener("keydown", onDown);
		return () => {
			window.removeEventListener("keydown", onDown);
		};
	}, [key]);

	return pressed;
}
