import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { authConstants } from "_constants";

export const OrganizationStatus = (props) => {
  const { item, style, ignoreActive } = props;

  const approvalText = authConstants.approvalStatusLabel[item.approvalStatus];
  const text = authConstants.statusLabel[item.status];

  if (item.approvalStatus === authConstants.approvalStatus.PENDING) {
    return <PendingStatus style={style}>{approvalText}</PendingStatus>;
  } else if (item.approvalStatus === authConstants.approvalStatus.REJECTED) {
    return <RejectedStatus style={style}>{approvalText}</RejectedStatus>;
  } else if (!ignoreActive && item.status === authConstants.status.ACTIVE) {
    return <ActiveStatus style={style}>{text}</ActiveStatus>;
  } else if (item.status === authConstants.status.CLOSED) {
    return <ClosedStatus style={style}>{text}</ClosedStatus>;
  } else if (item.status === authConstants.status.SUSPEND) {
    return <SuspendStatus style={style}>{text}</SuspendStatus>;
  } else if (item.status === authConstants.status.BLOCKED) {
    return <BlockStatus style={style}>{text}</BlockStatus>;
  } else {
    return <div></div>;
  }
};

const RootStatus = styled.div`
  padding: 3px 0px;
  background-color: red;
  text-align: center;
  border-radius: 3px;
  max-width: 60px;
  font-size: 11px;
`;

const SuspendStatus = styled(RootStatus)`
  color: black;
  background-color: #ffd95c;
`;

const ClosedStatus = styled(RootStatus)`
  color: white;
  background-color: #ff5c5c;
`;

const PendingStatus = styled(RootStatus)`
  color: black;
  background-color: #ffd95c;
`;
const ActiveStatus = styled(RootStatus)`
  color: black;
  background-color: #5cff82;
`;
const RejectedStatus = styled(RootStatus)`
  color: white;
  background-color: #ff5c5c;
`;
const BlockStatus = styled(RootStatus)`
  color: white;
  background-color: #000000;
`;

OrganizationStatus.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
  ignoreActive: PropTypes.bool,
};
