import { authConstants, learningCentreConstants } from "_constants";

const initialState = {
	isFetching: false,
	isFetchError: false,
	centre: {},
	createLearningCentreSuccess: false,
	isCreateFetching: false,
	updateLearningCentreSuccess: false,
	deleteLearningCentreSuccess: false,
	isUpdateFetching: false,
	deleteInProgress: false,
	navigationObject: {
		root: {
			searchKey: "",
			district: "",
			category: "",
			sortingKey: "name",
			sortingDirection: "ASC",
			learningCentreList: [],
			numOfPage: 1,
			currentPage: 1,
			totalNumOfRecord: 0,
		},
	},
};

const learningCentreReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			let navigationObject = initialState.navigationObject;
			if (typeof state.navigationObject?.root != "undefined") {
				navigationObject = {
					...state.navigationObject,
					root: {
						...state.navigationObject.root,
						currentPage: 1,
					},
				};
			}
			return {
				...state,
				navigationObject: navigationObject,
			};
		}
		case learningCentreConstants.type.FETCH_LEARNING_CENTRE_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
				isFetchError: false,
			};
		}
		case learningCentreConstants.type.FETCH_LEARNING_CENTRE_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
				isFetchError: true,
			};
		}
		case learningCentreConstants.type.FETCH_LEARNING_CENTRE_LIST_SUCCESS: {
			const navigationObject = {
				searchKey: action.searchKey,
				district: action.district,
				category: action.category,
				sortingKey: action.sortingKey,
				sortingDirection: action.sortingDirection,
				totalNumOfRecord: action.totalNum,
				numOfPage: action.totalPage,
				currentPage: action.currentPage,
				learningCentreList: action.learningCentreList,
			};
			return {
				...state,
				isFetching: false,
				isFetchError: false,
				navigationObject: {
					root: {
						...navigationObject,
					},
				},
			};
		}
		case learningCentreConstants.type.FETCH_CENTRE_REQUEST: {
			return {
				...state,
				isFetching: true,
				isFetchError: false,
				centre: {},
			};
		}
		case learningCentreConstants.type.FETCH_CENTRE_FAILURE: {
			return {
				...state,
				isFetching: false,
				isFetchError: true,
			};
		}
		case learningCentreConstants.type.FETCH_CENTRE_SUCCESS: {
			return {
				...state,
				isFetching: false,
				isFetchError: false,
				centre: action.centre,
			};
		}
		case learningCentreConstants.type.CREATE_LEARNING_CENTRE_REQUEST: {
			return {
				...state,
				createLearningCentreSuccess: false,
				isCreateFetching: true,
			};
		}
		case learningCentreConstants.type.CREATE_LEARNING_CENTRE_FAILURE: {
			return {
				...state,
				createLearningCentreSuccess: false,
				isCreateFetching: false,
			};
		}
		case learningCentreConstants.type.CREATE_LEARNING_CENTRE_SUCCESS: {
			return {
				...state,
				createLearningCentreSuccess: true,
				isCreateFetching: false,
			};
		}
		case learningCentreConstants.type.UPDATE_LEARNING_CENTRE_REQUEST: {
			return {
				...state,
				updateLearningCentreSuccess: false,
				isUpdateFetching: true,
			};
		}
		case learningCentreConstants.type.UPDATE_LEARNING_CENTRE_FAILURE: {
			return {
				...state,
				updateLearningCentreSuccess: false,
				isUpdateFetching: false,
			};
		}
		case learningCentreConstants.type.UPDATE_LEARNING_CENTRE_SUCCESS: {
			return {
				...state,
				updateLearningCentreSuccess: true,
				isUpdateFetching: false,
			};
		}
		case learningCentreConstants.type.DELETE_LEARNING_CENTRE_REQUEST: {
			return {
				...state,
				deleteLearningCentreSuccess: false,
				deleteInProgress: true,
			};
		}
		case learningCentreConstants.type.DELETE_LEARNING_CENTRE_FAILURE: {
			return {
				...state,
				deleteLearningCentreSuccess: false,
				deleteInProgress: false,
			};
		}
		case learningCentreConstants.type.DELETE_LEARNING_CENTRE_SUCCESS: {
			return {
				...state,
				deleteLearningCentreSuccess: true,
				deleteInProgress: false,
			};
		}
		case learningCentreConstants.type.RESET_LEARNING_CENTRE_SUCCESS: {
			return {
				...state,
				createLearningCentreSuccess: false,
				isCreateFetching: false,
				updateLearningCentreSuccess: false,
				deleteLearningCentreSuccess: false,
				isUpdateFetching: false,
				deleteInProgress: false,
			};
		}
		default: {
			return state;
		}
	}
};

export default learningCentreReducer;
