import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { authConstants, generalConstants } from "_constants";
import { authAction } from "actions";
import { history, validatePassword } from "_util/_helper";
import { useKey } from "_util/hook";
import { Loading, SubmitButton, CancelButton, TextField, BlockMobileOverlay } from "components/general";
import { toast } from "react-toastify";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import { PageContainer, LeftContainer, RightContainer, ContentContainer } from "components/layout";
import { getAuth } from "firebase/auth";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";
import { Desktop } from "components/responsive";

export const ChangePasswordPage = () => {
	const initForm = {
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
	};

	const loggedIn = useSelector(({ auth }) => auth.loggedIn);
	const changePasswordRequesting = useSelector(({ auth }) => auth.changePasswordRequesting);
	const changePasswordSuccess = useSelector(({ auth }) => auth.changePasswordSuccess);
	const changePasswordFailure = useSelector(({ auth }) => auth.changePasswordFailure);

	const [formData, setFormData] = useState(initForm);
	const [isChanged, setIsChanged] = useState(false);
	const [kickOut, setKickOut] = useState(false);
	const dispatch = useDispatch();
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(2 * 1000));
	const [initialized, setInitialized] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);

	const enterPress = useKey("enter");

	useEffect(() => {
		if (enterPress) {
			handleSubmit();
		}
	}, [enterPress]);

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
		setIsChanged(true);
	};

	const handleBack = () => {
		history.goBack();
	};

	useEffect(() => {
		if (loggedIn) {
			dispatch({ type: authConstants.type.CHANGE_PASSWORD_INIT });
		}
		setInitialized(true);
	}, [dispatch]);

	useEffect(() => {
		if (initialized) {
			if (!changePasswordRequesting && changePasswordSuccess) {
				setIsChanged(false);
				setSubmitting(false);
				history.goBack();
			}
		}
	}, [initialized, changePasswordRequesting, changePasswordSuccess]);

	useEffect(() => {
		if (changePasswordFailure) {
			setSubmitting(false);
		}
	}, [changePasswordFailure]);

	const handleSubmit = () => {
		setSubmitting(true);
		if (!formData.currentPassword) {
			toast.error("請填寫現有密碼");
			setSubmitting(false);
			return;
		}

		if (!formData.newPassword) {
			toast.error("請填寫新密碼");
			setSubmitting(false);
			return;
		}

		if (!formData.confirmPassword) {
			toast.error("請填寫再次輸入新密碼");
			setSubmitting(false);
			return;
		}

		if (formData.newPassword !== formData.confirmPassword) {
			toast.error("確認密碼不匹配");
			setSubmitting(false);
			return;
		}

		const message = validatePassword(formData.newPassword, formData.currentPassword);
		if (message) {
			toast.error(message);
			setSubmitting(false);
			return;
		}

		if (!debouncer.token()) {
			toast.error(`請稍後再試`);
			setSubmitting(false);
			return;
		}
		if (getAuth().currentUser) {
			dispatch(
				authAction.changePassword(getAuth().currentUser, { password: formData.currentPassword, confirmPassword: formData.confirmPassword })
			);
		} else {
			setIsChanged(false);
			setKickOut(true);
			setSubmitting(false);
		}
	};

	useEffect(() => {
		if (kickOut) dispatch(authAction.kickout());
	}, [kickOut]);

	return (
		<React.Fragment>
			{!!changePasswordRequesting && <Loading />}
			<Prompt when={isChanged && loggedIn} message="你有未儲存的變更，確定不儲存下離開？" />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.ACCOUNT_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<AdminSectionbar label={generalConstants.TAB_NAME.ACCOUNT_MANAGEMENT} title={generalConstants.NAV_TAB.CHANGE_PASSWORD.key} />
					<ContentContainer padding="16px 20px 17px">
						<RowInBlock>
							<ContentLeft>
								<TitleFieldLabel>- 更改密碼</TitleFieldLabel>
								<Block style={{ width: "90%" }} marginTop="0">
									<TextFieldLabel>現有密碼</TextFieldLabel>
									<TextField
										placeholder="現有密碼"
										type="password"
										value={formData.currentPassword}
										name="currentPassword"
										handleChange={handleChange}
										disabled={isSubmitting}
									/>
								</Block>
								<Block style={{ width: "90%" }} marginTop="19px">
									<TextFieldLabel>新密碼</TextFieldLabel>
									<TextField
										placeholder="新密碼"
										type="password"
										value={formData.newPassword}
										name="newPassword"
										handleChange={handleChange}
										disabled={isSubmitting}
									/>
								</Block>
								<Block style={{ width: "90%" }} marginTop="19px">
									<TextFieldLabel>請再次輸入新密碼</TextFieldLabel>
									<TextField
										placeholder="請再次輸入新密碼"
										type="password"
										value={formData.confirmPassword}
										name="confirmPassword"
										handleChange={handleChange}
										disabled={isSubmitting}
									/>
								</Block>
							</ContentLeft>

							<ContentRight></ContentRight>
						</RowInBlock>
						<RowInBlock marginTop={"10px"} style={{ justifyContent: "flex-end" }}>
							<CancelButton label={"取消"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
							<SubmitButton
								label={"確認"}
								handleOnClick={handleSubmit}
								active={isChanged && !changePasswordRequesting}
								disabled={changePasswordRequesting}
							/>
						</RowInBlock>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
			<BlockMobileOverlay />
		</React.Fragment>
	);
};

const ContentLeft = styled.div`
	flex: 0 0 400px;
	min-height: 400px;
	padding-left: 10px;
	max-width: 220px;
`;

const ContentRight = styled.div`
	flex: 1;
	padding-left: 20px;
`;

const Block = styled.div`
	margin-top: ${(props) => props.marginTop || "40px"};
	flex-direction: column;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const TextFieldLabel = styled.div`
	color: black;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 10px;
`;
const TitleFieldLabel = styled.div`
	color: #333399;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 10px;
`;
