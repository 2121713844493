import { authConstants, learningCoursesConstants, learningCentreConstants } from "_constants";

const initialState = {
	isFetching: false,
	isFetchError: false,
	course: {},
	createLearningCourseSuccess: false,
	isCreateFetching: false,
	updateLearningCourseSuccess: false,
	deleteLearningCourseSuccess: true,
	isUpdateFetching: false,
	deleteInProgress: true,
	navigationObject: {
		root: {
			searchKey: "",
			district: "",
			category: "",
			sortingKey: "name",
			sortingDirection: "ASC",
			learningCentreList: [],
			numOfPage: 1,
			currentPage: 1,
			totalNumOfRecord: 0,
		},
		center: {
			searchKey: "",
			district: "",
			category: "",
			sortingKey: "name",
			sortingDirection: "ASC",
			learningCentreList: [],
			numOfPage: 1,
			currentPage: 1,
			totalNumOfRecord: 0,
			learningCenterKey: "",
		},
	},
};

const learningCoursesReducer = (state = initialState, action) => {
	switch (action.type) {
		case authConstants.type.UNAUTHORIZED_ERROR: {
			let navigationObject = initialState.navigationObject;
			if (typeof state.navigationObject?.root != "undefined") {
				navigationObject = {
					...state.navigationObject,
					root: {
						...state.navigationObject.root,
						currentPage: 1,
					},
				};
			}
			if (typeof state.navigationObject?.center != "undefined") {
				navigationObject = {
					...state.navigationObject,
					center: {
						...state.navigationObject.center,
						currentPage: 1,
					},
				};
			}
			return {
				...state,
				navigationObject: navigationObject,
			};
		}
		case learningCoursesConstants.type.FETCH_LEARNING_COURSES_LIST_REQUEST: {
			return {
				...state,
				isFetching: true,
				isFetchError: false,
			};
		}
		case learningCoursesConstants.type.FETCH_LEARNING_COURSES_LIST_FAILURE: {
			return {
				...state,
				isFetching: false,
				isFetchError: true,
			};
		}
		case learningCentreConstants.type.FETCH_CENTRE_SUCCESS: {
			if (action.navigationKey) {
				const navigationObject = {
					searchKey: action.searchKey || "",
					district: action.district || "",
					category: action.category || "",
					sortingKey: action.sortingKey || "",
					sortingDirection: action.sortingDirection || "",
					totalNumOfRecord: action.totalNum,
					numOfPage: action.totalPage,
					currentPage: action.currentPage,
					learningCoursesList: action.learningCoursesList,
					learningCenterKey: action?.centre?._key,
				};
				return {
					...state,
					isFetching: false,
					isFetchError: false,
					navigationObject: {
						...state.navigationObject,
						[`${action.navigationKey}`]: {
							...navigationObject,
						},
					},
				};
			} else {
				return state;
			}
		}
		case learningCoursesConstants.type.FETCH_LEARNING_COURSES_LIST_SUCCESS: {
			const navigationObject = {
				searchKey: action.searchKey,
				district: action.district,
				category: action.category,
				sortingKey: action.sortingKey,
				sortingDirection: action.sortingDirection,
				totalNumOfRecord: action.totalNum,
				numOfPage: action.totalPage,
				currentPage: action.currentPage,
				learningCoursesList: action.learningCoursesList,
				learningCenterKey: action.learningCenterKey,
			};
			return {
				...state,
				isFetching: false,
				isFetchError: false,
				navigationObject: {
					...state.navigationObject,
					[`${action.navigationKey}`]: {
						...navigationObject,
					},
				},
			};
		}
		case learningCoursesConstants.type.FETCH_COURSES_REQUEST: {
			return {
				...state,
				isFetching: true,
				isFetchError: false,
				course: {},
			};
		}
		case learningCoursesConstants.type.FETCH_COURSES_FAILURE: {
			return {
				...state,
				isFetching: false,
				isFetchError: true,
			};
		}
		case learningCoursesConstants.type.FETCH_COURSES_SUCCESS: {
			return {
				...state,
				isFetching: false,
				isFetchError: false,
				course: action.course,
			};
		}
		case learningCoursesConstants.type.CREATE_LEARNING_COURSES_REQUEST: {
			return {
				...state,
				createLearningCourseSuccess: false,
				isCreateFetching: true,
			};
		}
		case learningCoursesConstants.type.CREATE_LEARNING_COURSES_FAILURE: {
			return {
				...state,
				createLearningCourseSuccess: false,
				isCreateFetching: false,
			};
		}
		case learningCoursesConstants.type.CREATE_LEARNING_COURSES_SUCCESS: {
			return {
				...state,
				createLearningCourseSuccess: true,
				isCreateFetching: false,
			};
		}
		case learningCoursesConstants.type.UPDATE_LEARNING_COURSES_REQUEST: {
			return {
				...state,
				updateLearningCourseSuccess: false,
				isUpdateFetching: true,
			};
		}
		case learningCoursesConstants.type.UPDATE_LEARNING_COURSES_FAILURE: {
			return {
				...state,
				updateLearningCourseSuccess: false,
				isUpdateFetching: false,
			};
		}
		case learningCoursesConstants.type.UPDATE_LEARNING_COURSES_SUCCESS: {
			return {
				...state,
				updateLearningCourseSuccess: true,
				isUpdateFetching: false,
			};
		}
		case learningCoursesConstants.type.DELETE_LEARNING_COURSES_REQUEST: {
			return {
				...state,
				deleteLearningCourseSuccess: true,
				deleteInProgress: true,
			};
		}
		case learningCoursesConstants.type.DELETE_LEARNING_COURSES_FAILURE: {
			return {
				...state,
				deleteLearningCourseSuccess: false,
				deleteInProgress: false,
			};
		}
		case learningCoursesConstants.type.DELETE_LEARNING_COURSES_SUCCESS: {
			return {
				...state,
				deleteLearningCourseSuccess: true,
				deleteInProgress: false,
			};
		}
		case learningCoursesConstants.type.RESET_LEARNING_COURSES_SUCCESS: {
			return {
				...state,
				createLearningCourseSuccess: false,
				updateLearningCourseSuccess: false,
				deleteLearningCourseSuccess: false,
				isCreateFetching: false,
				isUpdateFetching: false,
				deleteInProgress: false,
				fetchMsgSuccess: true,
			};
		}
		default: {
			return state;
		}
	}
};

export default learningCoursesReducer;
