import { generalConstants, learningCentreConstants } from "_constants";
import { learningCentreService } from "services";
import { toast } from "react-toastify";
import { history } from "_util/_helper";

export const learningCentreAction = {
	createLearningCenter,
	updateLearningCenter,
	getLearningCenters,
	getLearningCenterByKey,
	deleteLearningCenter,
};

function deleteLearningCenter(data) {
	return (dispatch) => {
		dispatch(request());
		learningCentreService.deleteLearningCenter(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: learningCentreConstants.type.DELETE_LEARNING_CENTRE_REQUEST };
	}
	function failure() {
		return { type: learningCentreConstants.type.DELETE_LEARNING_CENTRE_FAILURE };
	}
	function success() {
		return { type: learningCentreConstants.type.DELETE_LEARNING_CENTRE_SUCCESS };
	}
}

function createLearningCenter(data) {
	return (dispatch) => {
		dispatch(request());
		learningCentreService.createLearningCenter(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return {
			type: learningCentreConstants.type.CREATE_LEARNING_CENTRE_REQUEST,
		};
	}
	function failure() {
		return {
			type: learningCentreConstants.type.CREATE_LEARNING_CENTRE_FAILURE,
		};
	}
	function success() {
		return {
			type: learningCentreConstants.type.CREATE_LEARNING_CENTRE_SUCCESS,
		};
	}
}

function updateLearningCenter(data) {
	return (dispatch) => {
		dispatch(request());
		learningCentreService.updateLearningCenter(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return {
			type: learningCentreConstants.type.UPDATE_LEARNING_CENTRE_REQUEST,
		};
	}
	function failure() {
		return {
			type: learningCentreConstants.type.UPDATE_LEARNING_CENTRE_FAILURE,
		};
	}
	function success() {
		return {
			type: learningCentreConstants.type.UPDATE_LEARNING_CENTRE_SUCCESS,
		};
	}
}

function getLearningCenters(data) {
	return (dispatch) => {
		dispatch(request());
		learningCentreService.getLearningCenters(data).then(
			(response) => {
				dispatch(success(response, data));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return {
			type: learningCentreConstants.type.FETCH_LEARNING_CENTRE_LIST_REQUEST,
		};
	}
	function failure() {
		return {
			type: learningCentreConstants.type.FETCH_LEARNING_CENTRE_LIST_FAILURE,
		};
	}
	function success(response, originalInput) {
		const { totalItems, items } = response;

		let sortingKey = "name",
			sortingDirection = "ASC";
		const sortingArray = originalInput?.sort?.split(":");
		if (sortingArray.length === 2) {
			sortingKey = sortingArray[0];
			sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
		}
		const searchKey = originalInput.search || "";
		const district = originalInput.district || "";
		const category = originalInput.category || "";
		return {
			type: learningCentreConstants.type.FETCH_LEARNING_CENTRE_LIST_SUCCESS,
			totalNum: totalItems,
			totalPage: totalItems / 10 === parseInt(totalItems / 10) ? parseInt(totalItems / 10) : parseInt(totalItems / 10) + 1,
			currentPage: data.p,
			learningCentreList: items,
			searchKey,
			district,
			category,
			sortingKey,
			sortingDirection,
		};
	}
}

function getLearningCenterByKey(key, data) {
	return (dispatch) => {
		dispatch(request());
		learningCentreService.getLearningCenterByKey(key, data).then(
			(response) => {
				dispatch(success(response, data));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
				if (error.error?.status !== 401) {
					history.push(generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT);
				}
			}
		);
	};

	function request() {
		return { type: learningCentreConstants.type.FETCH_CENTRE_REQUEST };
	}
	function failure() {
		return { type: learningCentreConstants.type.FETCH_CENTRE_FAILURE };
	}
	function success(centre, inputData) {
		if (inputData && inputData.includeCourse) {
			const { totalItems, items } = centre?.courses || {};
			let sortingKey = "name",
				sortingDirection = "ASC";
			const sortingArray = inputData?.sort?.split(":");
			if (sortingArray.length === 2) {
				sortingKey = sortingArray[0];
				sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
			}
			return {
				type: learningCentreConstants.type.FETCH_CENTRE_SUCCESS,
				centre: centre?.lc,
				navigationKey: inputData.navigationKey,
				totalNum: totalItems,
				totalPage: totalItems / 10 === parseInt(totalItems / 10) ? parseInt(totalItems / 10) : parseInt(totalItems / 10) + 1,
				currentPage: inputData.p,
				learningCoursesList: items,
				sortingDirection,
				sortingKey,
				searchKey: inputData.search || "",
				district: inputData.district || "",
				category: inputData.category || "",
			};
		} else {
			return {
				type: learningCentreConstants.type.FETCH_CENTRE_SUCCESS,
				centre,
			};
		}
	}
}
