import React from "react";
import { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";

export const MatchMedia = (props) => {
  const { query, children } = props;
  const firstMatch = match(query);
  const [matched, setMatched] = useState(firstMatch);

  useLayoutEffect(() => {
    function updateMatched() {
      const nextMatched = match(query);
      setMatched(nextMatched);
    }

    updateMatched();

    window.addEventListener("resize", updateMatched);
    return () => window.removeEventListener("resize", updateMatched);
  }, [query]);

  if (!matched) {
    return <></>;
  }

  if (!children) {
    return <></>;
  }

  return <>{children}</>;
}

export function match(query) {
  const matchMedia = window.matchMedia(query);
  return matchMedia.matches;
}

MatchMedia.propTypes = {
  children: PropTypes.any,
  query: PropTypes.string,
};


MatchMedia.defaultProps = {
	children: undefined,
  query: undefined,
};