import styled from "styled-components";
import { MOBILE_MAX_WIDTH, DESKTOP_MIN_WIDTH } from "_constants";

export const PageContainer = styled.div`
	width: 100%;
	margin: auto;
	height: 100%;
	display: flex;

	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex-direction: column;
	}
`;

export const LeftContainer = styled.div`
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		flex: 0 0 171px;
	}
`;

export const RightContainer = styled.div`
	flex: 1;
	background-color: #f2f2f2;
`;

export const ContentContainer = styled.div`
	margin: 0px 20px 0px 20px;
	padding: ${(props) => props.padding || "16px 0px 17px"};
	border-radius: 3px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		margin: 0px;
	}
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
	}
	background-color: #ffffff;
	/*
    overflow-y: scroll;
    height: 100vh;
    */
	&:after {
		clear: both;
		content: "";
		display: block;
	}
`;

export const PaginationContainer = styled.div`
	padding: 23px 0px 20px 0px;
	margin: 0 10px;
	display: flex;
	justify-content: left;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		justify-content: center;
	}
`;
