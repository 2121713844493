export const generalConstants = {
	BUTTON_TYPE: {
		SUBMIT: "SUBMIT",
		WARNING: "WARNING",
		IMPORT: "IMPORT",
	},
	NAV_TAB: {
		LEARNING_CENTRE_MANAGEMENT: {
			key: "LEARNING_CENTRE_MANAGEMENT",
			show: true,
		},
		LEARNING_CENTRE_DETAIL: { key: "LEARNING_CENTRE_DETAIL", show: false },
		EDIT_LEARNING_CENTRE: { key: "EDIT_LEARNING_CENTRE", show: false },
		CREATE_LEARNING_CENTRE: { key: "CREATE_LEARNING_CENTRE", show: false },

		LEARNING_COURSE_MANAGEMENT: {
			key: "LEARNING_COURSE_MANAGEMENT",
			show: true,
		},
		LEARNING_COURSE_DETAIL: { key: "LEARNING_COURSE_DETAIL", show: false },
		EDIT_LEARNING_COURSE: { key: "EDIT_LEARNING_COURSE", show: false },
		CREATE_LEARNING_COURSE: { key: "CREATE_LEARNING_COURSE", show: false },

		CHANGE_PASSWORD: { key: "CHANGE_PASSWORD", show: false },
		ACCOUNT_MANAGEMENT: { key: "ACCOUNT_MANAGEMENT", show: false },
	},
	TAB_NAME: {
		LEARNING_CENTRE_MANAGEMENT: "補習社管理",
		EDIT_LEARNING_CENTRE: "編輯補習社",
		LEARNING_CENTRE_DETAIL: "補習社資料",
		CREATE_LEARNING_CENTRE: "新增補習社",
		LEARNING_COURSE_MANAGEMENT: "課程管理",
		LEARNING_COURSE_DETAIL: "課程管理",
		EDIT_LEARNING_COURSE: "編輯課程",
		CREATE_LEARNING_COURSE: "新增課程",

		ABOUT_US: "關於亞網",
		CUSTOMER_SERVICE: "客戶服務",
		FAQ: "常見問題",
		TERMS_CONDITION_PRIVACY: "條款及細則和私隱政策",
		CONTACT_US: "聯繫我們",
		ALL_DIFF: "全部網頁更新",
		DIFF_DETAIL: "網頁更新詳情",
		CHANGE_PASSWORD: "更改密碼",
		ACCOUNT_MANAGEMENT: "管理帳戶資料",
		DATA_SCRAP: "數據抓取",
		DATA_SCRAP_DETAIL: "抓取變化詳情",
	},
	FOOTER_TAB: {
		ABOUT_US: { key: "ABOUT_US", show: true },
		CUSTOMER_SERVICE: { key: "CUSTOMER_SERVICE", show: true },
		FAQ: { key: "FAQ", show: true },
		TERMS_CONDITION_PRIVACY: { key: "TERMS_CONDITION_PRIVACY", show: true },
		CONTACT_US: { key: "CONTACT_US", show: true },
	},
	PATH: {
		LOGIN: "/",
		REGISTER: "/register",
		AUTH: "/auth",
		FORGET_PASSWORD: "/forgetPassword",
		CHANGE_PASSWORD: "/changePassword",
		EDIT_ORGANIZATION: "/editOrganization",

		LEARNING_CENTRE_MANAGEMENT: "/learningCentreManagement",
		LEARNING_COURSE_MANAGEMENT: "/courseManagement",
		LEARNING_CENTER: "/learningCenter",
		LEARNING_COURSE: "/course",

		ABOUT_US: "/about_us",
		CUSTOMER_SERVICE: "/cs",
		FAQ: "https://kidsontrack.hk/doc/Privacy_Policy.html",
		TERMS_CONDITION_PRIVACY: "https://kidsontrack.hk/doc/Terms_and_Conditions.html",
		CONTACT_US: "mailto:cs@squarebox.com.hk",
	},
	EMAIL_LANDING_PAGE_TITLE_RESET: "伴你童行 - 重設密碼",
	EMAIL_LANDING_PAGE_TITLE: "伴你童行 - 設定密碼",
};
