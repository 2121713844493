import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import _ from "lodash";

import {
	ChangePasswordPage,
	EditOrganizationPage,
	LoginPage,
	RegisterPage,
	LearningCentreManagementPage,
	LearningCentreDetailScreen,
	EditCentrePage,
	CourseManagementPage,
	CourseDetailScreen,
	EditCoursePage,
	EmailLandingPage,
} from "./Page";

import { PrivateRoute, Loading } from "components/general";
import { generalConstants } from "_constants";
import { authAction } from "actions";
import axios from "axios";
import { setupCache, buildKeyGenerator, buildWebStorage } from "axios-cache-interceptor";
import { history } from "_util/_helper";
import { toast } from "react-toastify";
// eslint-disable-next-line no-unused-vars
import { app } from "_util/firebase";
function App() {
	const user = useSelector(({ auth }) => auth.user);
	const loggedIn = useSelector(({ auth }) => auth.loggedIn);

	const isLogoutFetching = useSelector(({ auth }) => auth.isLogoutFetching);

	const dispatch = useDispatch();

	const onTokenExpired = useCallback(() => {
		dispatch(authAction.kickout());
	}, [dispatch]);

	const throttleOnTokenExpired = useCallback(_.throttle(onTokenExpired, 1000, { trailing: false }), []);

	useEffect(() => {
		return history.listen((location, action) => {
			if (
				action === "POP" &&
				!loggedIn &&
				location.pathname !== generalConstants.PATH.LOGIN &&
				location.pathname !== generalConstants.PATH.REGISTER &&
				location.pathname !== generalConstants.PATH.AUTH
			) {
				toast.error("連接逾時，請重新登入");
				history.push(generalConstants.PATH.LOGIN);
			}
		});
	}, [loggedIn]);

	useEffect(() => {
		setupCache(axios, {
			keyGenerator: buildKeyGenerator((request) => ({
				method: request.method,
				url: request.url,
			})),
			cache: {
				ttl: 30 * 60 * 1000,
			},
			storage: buildWebStorage(localStorage, "axios-cache:"),
		});
		axios.interceptors.response.use(
			(response) => {
				return response;
			},
			async (error) => {
				if (error.response?.status === 401) {
					window.localStorage.clear();
					throttleOnTokenExpired();
				}
				return Promise.reject(error);
			}
		);
	}, [onTokenExpired]);

	return (
		<div className="App">
			{!!isLogoutFetching && <Loading />}
			<Router history={history}>
				<Switch>
					<Route exact path={generalConstants.PATH.AUTH} render={() => <EmailLandingPage />} />
					<Route
						exact
						path="/"
						render={() => (!!loggedIn && user ? <Redirect to={`${generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT}`} /> : <LoginPage />)}
					/>
					<Route exact path={generalConstants.PATH.REGISTER} render={() => <RegisterPage />} />
					<PrivateRoute path={generalConstants.PATH.EDIT_ORGANIZATION} component={EditOrganizationPage} />
					<PrivateRoute path={generalConstants.PATH.CHANGE_PASSWORD} component={ChangePasswordPage} />

					<PrivateRoute path={generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT} component={LearningCentreManagementPage} />
					<PrivateRoute path={generalConstants.PATH.LEARNING_COURSE_MANAGEMENT} component={CourseManagementPage} />

					<PrivateRoute exact path={generalConstants.PATH.LEARNING_COURSE} component={CourseDetailScreen} />
					<PrivateRoute exact path={generalConstants.PATH.LEARNING_CENTER} component={LearningCentreDetailScreen} />
					<PrivateRoute exact path={`${generalConstants.PATH.LEARNING_CENTER}/course`} component={CourseDetailScreen} />

					<PrivateRoute path={`${generalConstants.PATH.LEARNING_CENTER}/add`} component={EditCentrePage} />
					<PrivateRoute path={`${generalConstants.PATH.LEARNING_CENTER}/course/add`} component={EditCoursePage} />
					<PrivateRoute path={`${generalConstants.PATH.LEARNING_COURSE}/add`} component={EditCoursePage} />

					<PrivateRoute path={`${generalConstants.PATH.LEARNING_CENTER}/edit`} component={EditCentrePage} />
					<PrivateRoute path={`${generalConstants.PATH.LEARNING_CENTER}/course/edit`} component={EditCoursePage} />
					<PrivateRoute path={`${generalConstants.PATH.LEARNING_COURSE}/edit`} component={EditCoursePage} />
					<Route path="*">
						<h1>404</h1>
					</Route>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
