import { initializeApp } from "firebase/app";
import { initializeAuth, browserLocalPersistence } from "firebase/auth";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_ORGANIZATION_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_ORGANIZATION_FIREBASE_AUTH_DOMAIN,
};
const app = initializeApp(firebaseConfig);
const auth = initializeAuth(app, {
	persistence: [browserLocalPersistence],
});
export { app, auth };
