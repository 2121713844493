import React from "react";
import styled from "styled-components";
import { DESKTOP_MIN_WIDTH } from "_constants";

export const BlockMobileOverlay = () => {
	return (
		<MobileOverLay>
			<OverLayMessage>
				<OverLayMessageTitle>
					<h1>請使用1024 x 768 以上之螢幕解析度進行瀏覽</h1>
				</OverLayMessageTitle>
			</OverLayMessage>
		</MobileOverLay>
	);
};

const MobileOverLay = styled.div`
	width: 100vw;
	height: 100vh;
	min-width: 100%;
	min-height: 100%;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	overflow-x: hidden;
	display: flex;
	display:none;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	&:before {
		background-color: #000000;
		content: "";
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -2;
		opacity: 0.75;
	}
	&:after {
		background-color: #000000;
		content: "";
		display: block;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: -1;
		opacity: 0.75;
	}
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		display: none;
	}
`;

const OverLayMessage = styled.div`
	color: #fff;
	text-shadow: #000000 2px 2px;
	min-width: 100%;
	min-height: 8em;
	position: relative;
	&:before {
		content: "";
		display: block;
		position: absolute;
		margin-left: 0;
		min-width: 100%;
		min-height: 8em;
		z-index: -1;
		opacity: 0.9;
		background-color: #000000;
	}
`;

const OverLayMessageTitle = styled.div`
	width: 100%;
	display: block;
	text-align: center;
`;
