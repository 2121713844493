import React, { useState, useEffect, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import InputFiles from "react-input-files";
import AddImg from "resources/img/add_img.png";

import "./css/Loader.css";

export const CourseImageUploader = (props) => {
	const { src, handleChange, handleClear, noImageLabel, isCover, isDisplayOnly } = props;

	const [hasImage, setHasImage] = useState(false);
	const [image, setImage] = useState(null);
	const [imageSrc, setImageSrc] = useState(null);

	const nocachet = useRef(Date.now());

	const loadImgSuccess = () => {
		if (!hasImage) setHasImage(true);
	};

	const loadImgFail = () => {
		if (hasImage) setHasImage(false);
	};

	const handleClearImage = () => {
		setHasImage(false);
		setImage(null);
		handleClear();
	};

	const onChangeImage = (files) => {
		console.log("files", files);
		setImage(files[0]);
		handleChange(files[0]);
	};

	const getImageSrc = useCallback(() => {
		const finalData = image ? URL.createObjectURL(image) : src + "?t=" + nocachet.current;
		return finalData;
	}, [image, src]);

	useEffect(() => {
		setImageSrc(getImageSrc());
	}, [image, getImageSrc]);

	useEffect(() => {
		if (typeof src === "object") {
			setImage(src);
		}
	}, [src]);

	return (
		<ImageUploader>
			{src ? (
				<ImageContainer
					style={{
						maxWidth: isCover ? "250px" : "80px",
						maxHeight: isCover ? "125px" : "80px",
					}}
				>
					{!hasImage && (
						<div className="lds-ring">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					)}
					<UploadImg style={{ display: hasImage ? "" : "none" }} src={imageSrc} onLoad={loadImgSuccess} onError={loadImgFail} />
					{!isDisplayOnly && (
						<IconButton
							onClick={handleClearImage}
							size="small"
							style={{
								backgroundColor: "#a7a7a7",
								padding: "1px",
								color: "white",
								position: "absolute",
								top: "-15px",
								right: "-15px",
							}}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					)}
				</ImageContainer>
			) : (
				<InputFiles
					accept=".png, .jpg, .jpeg"
					onChange={(files) => {
						onChangeImage(files);
					}}
				>
					<ImageContainer
						style={{
							maxWidth: isCover ? "250px" : "80px",
							maxHeight: isCover ? "125px" : "80px",
						}}
					>
						{!hasImage && (
							<NoImage
								style={{
									maxWidth: isCover ? "250px" : "70px",
									maxHeight: isCover ? "125px" : "70px",
								}}
								src={AddImg}
								alt={noImageLabel}
							></NoImage>
						)}
					</ImageContainer>
				</InputFiles>
			)}
		</ImageUploader>
	);
};

const ImageUploader = styled.div`
	text-align: left;
`;

const ImageContainer = styled.div`
	display: inline-block;
	position: relative;
`;

const UploadImg = styled.img`
	max-width: 100%;
`;

const NoImage = styled.img`
	background-color: #f2f2f2;
	line-height: 70px;
`;

CourseImageUploader.propTypes = {
	src: PropTypes.string,
	handleChange: PropTypes.func,
	handleClear: PropTypes.func,
	noImageLabel: PropTypes.string,
	isCover: PropTypes.bool,
	isDisplayOnly: PropTypes.bool,
};

CourseImageUploader.defaultProps = {
	src: null,
	handleChange: null,
	handleClear: null,
	noImageLabel: "沒有圖片",
	isCover: false,
	isDisplayOnly: false,
};
