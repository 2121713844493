import React, { useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { authAction } from "actions";
//import { WarningButton, ExcelImportButton } from "components/general";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { Link } from "react-router-dom";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MOBILE_MAX_WIDTH, DESKTOP_MIN_WIDTH } from "_constants";

import { generalConstants } from "_constants";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";
import { Desktop, desktop } from "components/responsive";

export const AdminUserInfo = () => {
	const user = useSelector(({ auth }) => auth.user);

	const dispatch = useDispatch();
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const handleLogout = () => {
		if (!debouncer.token()) {
			return;
		}
		dispatch(authAction.logout());
	};

	const handleEditOrganization = () => {
		setOpen(false);
	};

	const handleChangePassword = () => {
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	}

	return (
		<Container>
			<UserInfoLayer>
				<RowInBlock style={{ justifyContent: "flex-end" }}>
					<LogoutContainer>
						<Button ref={anchorRef} aria-controls={open ? "menu-list-grow" : undefined} aria-haspopup="true" onClick={handleToggle}>
							<Avatar src={user.profilePic} style={{ width: "32px", height: "32px" }} />
							<Desktop>
								<ColumnGroup style={{ marginLeft: 10, alignItems: "start" }}>
									<ContactPersonText>{user.contactPerson}</ContactPersonText>
									<OrganizationNameText>{user.name}</OrganizationNameText>
								</ColumnGroup>
							</Desktop>
							{open && <ExpandLessIcon />}
							{!open && <ExpandMoreIcon />}
						</Button>
						<Popper style={{ zIndex: 201, marginRight: "10px" }} placement={desktop() ? "" : "bottom-end"} open={open} anchorEl={anchorRef.current} role={undefined} transition>
							{({ TransitionProps, placement }) => (
								<Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
									<Paper>
										<ClickAwayListener onClickAway={handleClose}>
											<PopOverMenu onKeyDown={handleListKeyDown}>
												<PopMenuItem>
													<ItemBlock>
														<Avatar src={user.profilePic} style={{ width: "58px", height: "58px", margin: "auto" }} />
														<ColumnGroup>
															<ContactPersonText style={{ marginTop: 5 }}>{user.contactPerson}</ContactPersonText>
															<OrganizationNameText>{user.name}</OrganizationNameText>
														</ColumnGroup>
													</ItemBlock>
												</PopMenuItem>
												<PopMenuItem>
													<Link style={{ textDecoration: "none" }} to={generalConstants.PATH.EDIT_ORGANIZATION}>
														<ItemButton href="" onClick={handleEditOrganization}>
															管理帳戶資料
														</ItemButton>
													</Link>
												</PopMenuItem>
												<PopMenuItem>
													<Link style={{ textDecoration: "none" }} to={generalConstants.PATH.CHANGE_PASSWORD}>
														<ItemButton href="" onClick={handleChangePassword}>
															更改密碼
														</ItemButton>
													</Link>
												</PopMenuItem>
												<PopMenuItem>
													<LogoutItemButton href="" onClick={handleLogout}>
														登出
													</LogoutItemButton>
												</PopMenuItem>
											</PopOverMenu>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>
					</LogoutContainer>
				</RowInBlock>
			</UserInfoLayer>
		</Container>
	);
};

AdminUserInfo.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	sublabel: PropTypes.string,
	buttonLabel: PropTypes.string,
	handleOnClick: PropTypes.func,
};
const Container = styled.div`
	width: 100%;
	padding-bottom: 1px;
	height: 48px;
`;

const UserInfoLayer = styled.div`
	width: 100%;
	height: 100%;
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		background-color: #fafafa;
		border-bottom: 1px #dddddd solid;
	}

`;

const RowInBlock = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
`;

const LogoutContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
	
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-size: 12px;
	
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		min-width: 100px;
		margin-right: 30px;
	}
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		margin: 15px 5px;
	}
`;

const UserName = styled.span`
	padding: 0 5px 0 8px;
`;

const MenuItemUserName = styled.div`
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.15;
	letter-spacing: normal;
	color: #000000;
	margin: 5px 0;
`;

const PopOverMenu = styled.div`
	width: 180px;
`;

const PopMenuItem = styled.div`
	fontsize: 13px;

	border-bottom: #dddddd 1px solid;
	text-align: center;
`;
const ItemBlock = styled.div`
	padding: 11px;
`;

const LogoutItemButton = styled.div`
	display: block;
	padding: 11px;
	text-decoration: none;
	color: #fd5c5c;
	cursor: pointer;
	&:hover {
		background-color: #eeeeee;
	}
`;

const ItemButton = styled.div`
	display: block;
	padding: 11px;
	text-decoration: none;
	color: #0085b5;
	cursor: pointer;
	&:hover {
		background-color: #eeeeee;
	}
`;

const ColumnGroup = styled.div`
	display: flex;
	flex-direction: column;
`;

const ContactPersonText = styled.span`
	fontsize: 13px;
	line-height: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 500;
	color: #000000;
`;

const OrganizationNameText = styled.span`
	fontsize: 12px;
	line-height: 18px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #666666;
`;
