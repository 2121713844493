import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { authConstants } from "_constants";
import { RegisterContainer, RegisterCompleteMsg } from "components/auth";
import backgroundImageWeb from "resources/img/cover_web.jpg";
import backgroundImageMobile from "resources/img/cover_mobile.jpg";
import { MOBILE_MAX_WIDTH } from "_constants";

import { authAction } from "actions";
import { Loading } from "components/general";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

export const RegisterPage = () => {
	const dispatch = useDispatch();
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(1 * 1000));

	const isFetching = useSelector(({ auth }) => auth.isFetching);
	const registerRefNo = useSelector(({ auth }) => auth.registerRefNo);

	const [showRegisterCompleteMsg, setShowRegisterCompleteMsg] = useState(false);
	const reactHistory = useHistory();

	useEffect(() => {
		if (!isFetching && registerRefNo) {
			setShowRegisterCompleteMsg(true);
		}
		if (isFetching) {
			debouncer.token();
		}
	}, [isFetching, registerRefNo]);

	const closeCompleteMsg = () => {
		dispatch({ type: authConstants.type.REGISTER_RESET });
		setShowRegisterCompleteMsg(false);
		reactHistory.push(`/`);
	};

	const handleRegister = (data) => {
		if (!debouncer.token()) {
			toast.error(`請稍後再試`);
			return;
		}
		dispatch(authAction.register(data));
	};

	return (
		<Container>
			{!!isFetching && <Loading />}
			<RegisterContainer handleRegister={handleRegister} />
			<BackgroundImage />
			<RegisterCompleteMsg open={showRegisterCompleteMsg} onClose={closeCompleteMsg}></RegisterCompleteMsg>
		</Container>
	);
};

const Container = styled.div`
	height: 100vh;
	width: 100vw;
`;

const BackgroundImage = styled.div`
	height: 100%;
	width: 100%;
	position: fixed;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(${backgroundImageWeb});
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		background-image: url(${backgroundImageMobile});
	}
`;
