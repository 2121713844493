import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import styled from "styled-components";
import { generalConstants, learningCoursesConstants } from "_constants";
import { learningCoursesService } from "services";
import { learningCoursesAction } from "actions";
import { MOBILE_MAX_WIDTH, DESKTOP_MIN_WIDTH } from "_constants";

import { history } from "_util/_helper";
import _ from "lodash";
import moment from "moment";

import {
	CancelButton,
	CategoryButton,
	Loading,
	CourseImageUploader,
	CourseStatus,
	BlockMobileOverlay,
	AlertDialog,
} from "components/general";
import { useLocation, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
} from "components/layout";
import { Desktop, Mobile } from "components/responsive";

import editIcon from "resources/img/school_inner_pencil.svg";
import BinIcon from "resources/img/bin.svg";
import cloneIcon from "resources/img/clone_icon.png";
const FROM_CENTER = "learningCenter";
const FROM_COURSE = "course";
const week = ["一", "二", "三", "四", "五", "六", "日"];
export const CourseDetailScreen = () => {
	const location = useLocation();

	const [prevPage, setPrevPage] = useState(location?.state?.from);
	const [sourceCenter, setSourceCenter] = useState(location?.state?.sourceCenter);
	const [key, setCourseKey] = useState(location?.state?.sourceCourse?._key);
	const [sourceCourse, setSourceCourse] = useState(location?.state?.sourceCourse);
	const [isDeleteAlertShow, setIsDeleteAlertShow] = useState(false);

	const [course, setCourse] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const loggedIn = useSelector(({ auth }) => auth.loggedIn);
	const deleteInProgress = useSelector(({ course }) => course.deleteInProgress);

	const deleteLearningCourseSuccess = useSelector(({ course }) => course.deleteLearningCourseSuccess);

	const dispatch = useDispatch();

	useEffect(() => {
		setPrevPage(location?.state?.from);
		setSourceCenter(location?.state?.sourceCenter);
		setSourceCourse(location?.state?.sourceCourse);
		setCourseKey(location?.state?.sourceCourse?._key);
	}, [location?.state]);

	useEffect(() => {
		if (loggedIn) {
			if (key) {
				getCourseByKey(false);
			}
		}
	}, [dispatch, loggedIn]);

	useEffect(() => {
		if (!deleteInProgress && deleteLearningCourseSuccess) {
			dispatch({ type: learningCoursesConstants.type.RESET_LEARNING_COURSES_SUCCESS });
			toast.success("已成功刪除");
			history.goBack();
		} else if (!deleteInProgress && isDeleteAlertShow) {
			setIsDeleteAlertShow(false);
		}
	}, [deleteLearningCourseSuccess, deleteInProgress]);

	useEffect(() => {
		if (course && !_.isEmpty(course) && sourceCourse && !_.isEmpty(sourceCourse) && loggedIn) {
			if (moment(sourceCourse.lastUpdateTime).isAfter(course.lastUpdateTime)) {
				getCourseByKey(true);
			}
		}
	}, [course, loggedIn]);

	const getCourseByKey = (forceUpdate) => {
		setIsLoading(true);
		learningCoursesService.getLearningCourseByKey(key).then(
			(response) => {
				setIsLoading(false);
				if (response) {
					setCourse(response);
					if (forceUpdate) {
						setSourceCourse(response);
					}
				}
			},
			(error) => {
				setIsLoading(false);
				history.goBack();
				toast.error(error.error);
			}
		);
	};

	const handleBack = () => {
		history.goBack();
	};

	const getDistrict = (item) => {
		if (typeof item === "undefined") {
			return "-";
		}
		if (item === "") {
			return "-";
		}
		return learningCoursesConstants.DATA.district[item].label;
	};

	const getText = (text) => {
		return text ? text.replace(/\n/g, "<br />") : "";
	};

	const getLocationPath = () => {
		const pathArray = [];

		pathArray.push({
			title:
				generalConstants.TAB_NAME[
					prevPage === FROM_COURSE
						? generalConstants.NAV_TAB.LEARNING_COURSE_MANAGEMENT.key
						: generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key
				],
			isAction: true,
			target:
				prevPage === FROM_COURSE ? generalConstants.PATH.LEARNING_COURSE_MANAGEMENT : generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT,
		});
		if (prevPage === FROM_CENTER) {
			pathArray.push({
				title: course.learningCenterName,
				isAction: true,
				target: generalConstants.PATH.LEARNING_CENTER,
				stateObj: { from: prevPage, sourceCenter: sourceCenter },
			});
		}
		pathArray.push({
			title: course && course.name && !_.isEmpty(course.name) ? course.name || "-" : course.nameEng || "-",
			isAction: false,
			target: "",
		});

		return pathArray;
	};

	const handleCloseDeleteAlert = () => {
		setIsDeleteAlertShow(false);
	};

	const handleDelete = () => {
		const data = {
			key: course._key,
			rev: course._rev,
		};

		dispatch(learningCoursesAction.deleteLearningCourse(data));
	};

	return (
		<React.Fragment>
			{!!isLoading && <Loading />}
			<AlertDialog active={isDeleteAlertShow} handleOnClose={handleCloseDeleteAlert} handleOnYes={handleDelete} title={"確定移除課程？"} />
			<PageContainer>
				<LeftContainer>
					<AdminMenubar
						section={
							prevPage === FROM_COURSE
								? generalConstants.NAV_TAB.LEARNING_COURSE_MANAGEMENT.key
								: generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key
						}
					/>
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<AdminSectionbar
						title={generalConstants.NAV_TAB.LEARNING_COURSE_DETAIL.key}
						label={course && course.name && !_.isEmpty(course.name) ? course.name || "-" : course.nameEng || "-"}
						locationPathArray={getLocationPath()}
					/>
					<ContentContainer padding="0px 0px 17px">
						<SearchBar
							style={{
								paddingLeft: "20px",
								paddingRight: "20px",
								paddingTop: "20px",
							}}
						>
							<SearchBarLeft>
								<RowInSearchBlock marginBottom="0px">
									<Title>- 課程詳情</Title>
								</RowInSearchBlock>
							</SearchBarLeft>
							<SearchBarRight>
								<RowInSearchBlock style={{ float: "right" }} marginBottom="0px">
									<Link
										style={{ textDecoration: "none" }}
										to={{
											pathname:
												prevPage === FROM_CENTER
													? `${generalConstants.PATH.LEARNING_CENTER}/course/add`
													: `${generalConstants.PATH.LEARNING_COURSE}/add`,
											state:
												prevPage === FROM_CENTER
													? { from: prevPage, sourceCenter: sourceCenter, cloneCourse: course, sourceCourse: course }
													: { from: prevPage, cloneCourse: course, sourceCourse: course },
										}}
									>
										<Button aria-label="複製" startIcon={<img alt="" src={cloneIcon} />}>
											<SubTitle>
												<Desktop>複製課程</Desktop>
												<Mobile>複製</Mobile>
											</SubTitle>
										</Button>
									</Link>
									<Button onClick={() => setIsDeleteAlertShow(true)} startIcon={<img alt="" src={BinIcon} />}>
										<SubTitle>
											<Desktop>刪除課程</Desktop>
											<Mobile>刪除</Mobile>
										</SubTitle>
									</Button>
									<Link
										style={{ textDecoration: "none" }}
										to={{
											pathname:
												prevPage === FROM_CENTER
													? `${generalConstants.PATH.LEARNING_CENTER}/course/edit`
													: `${generalConstants.PATH.LEARNING_COURSE}/edit`,
											state:
												prevPage === FROM_CENTER
													? { from: prevPage, sourceCenter: sourceCenter, sourceCourse: course }
													: { from: prevPage, sourceCourse: course },
										}}
									>
										<Button startIcon={<img alt="" src={editIcon} />}>
											<SubTitle>
												<Desktop>修改課程</Desktop>
												<Mobile>修改</Mobile>
											</SubTitle>
										</Button>
									</Link>
								</RowInSearchBlock>
							</SearchBarRight>
						</SearchBar>
						<ContentColumnsContainer>
							<ContentLeft>
								<Block>
									<TextFieldLabel>狀態</TextFieldLabel>
									{course && course.status ? (
										<div style={{ maxWidth: 60 }}>
											<CourseStatus course={course} />
										</div>
									) : (
										<TextFieldValue>-</TextFieldValue>
									)}
								</Block>
								<Block>
									<BlockGroup>
										<div style={{ marginRight: "10px", width: "50%" }}>
											<TextFieldLabel>課程名稱 (中文)</TextFieldLabel>
											<TextFieldValue>{course.name || "-"}</TextFieldValue>
										</div>
										<div style={{ width: "50%" }}>
											<TextFieldLabel>補習社名稱</TextFieldLabel>
											<TextFieldValue>{course.learningCenterName || "-"}</TextFieldValue>
										</div>
									</BlockGroup>
									<TextFieldLabel style={{ marginTop: "10px" }}>Course Name (English)</TextFieldLabel>
									<TextFieldValue>{course.nameEng || "-"}</TextFieldValue>
								</Block>
								<BlockGroup>
									<Block>
										<TextFieldLabel>課程費用</TextFieldLabel>
										<BlockGroup>
											<TextFieldValue>
												{typeof course.fee != "undefined"
													? course.fee === 0
														? !_.isEmpty(course.feeBase)
															? `免費 / ${course.feeBase}`
															: "免費"
														: !_.isEmpty(course.feeBase)
														? `${course.fee} / ${course.feeBase}`
														: course.fee
													: course.feeBase || "-"}
											</TextFieldValue>
										</BlockGroup>
									</Block>
									<Block>
										<TextFieldLabel>課程導師</TextFieldLabel>
										<TextFieldValue>{course.tutor || "-"}</TextFieldValue>
									</Block>
								</BlockGroup>
								<BlockGroup>
									<Block>
										<TextFieldLabel>年齡範圍</TextFieldLabel>
										<TextFieldValue>
											{typeof course.minAge != "undefined" && typeof course.maxAge != "undefined"
												? `${course.minAge} ~ ${course.maxAge}歲`
												: typeof course.minAge != "undefined"
												? `${course.minAge}歲以上`
												: typeof course.maxAge != "undefined"
												? `${course.maxAge}歲以下`
												: `-`}
										</TextFieldValue>
									</Block>
								</BlockGroup>
								<BlockGroup>
									<Block>
										<TextFieldLabel>課程舉行地點</TextFieldLabel>
										<TextFieldValue>{course.isOnline ? "網上課程" : course.venue || "-"}</TextFieldValue>
									</Block>
									{!course.isOnline && (
										<Block>
											<TextFieldLabel>地區</TextFieldLabel>
											<TextFieldValue>{getDistrict(course.district)}</TextFieldValue>
										</Block>
									)}
								</BlockGroup>
								<Block>
									<TextFieldLabel>課程特色</TextFieldLabel>
									<TextFieldValue
										dangerouslySetInnerHTML={{
											__html: getText(course.curriculum) || "-",
										}}
									/>
								</Block>
							</ContentLeft>
							<ContentLeft>
								<BlockGroup>
									<Block flex="0 0 320px">
										<TextFieldLabel>課程內容</TextFieldLabel>
										<TextFieldValue
											dangerouslySetInnerHTML={{
												__html: getText(course.content) || "-",
											}}
										/>
									</Block>
								</BlockGroup>
								<Block>
									<TextFieldLabel>課程週期</TextFieldLabel>
									{course && course.timeslot ? (
										week.map((day, index) => {
											var timeString = "休息";
											if (typeof course.timeslot[index + 1] === "object" && Array.isArray(course.timeslot[index + 1])) {
												course.timeslot[index + 1].forEach((item, index) => {
													if (index === 0) {
														timeString = `${item.startTime} - ${item.endTime}`;
													} else {
														timeString = `${timeString} / ${item.startTime} - ${item.endTime}`;
													}
												});
											}

											return (
												<BlockGroup key={`timeslot_${day}_${index}`} style={{ width: "100%", marginBottom: "10px" }}>
													<TextFieldValue>{`星期${day}`}</TextFieldValue>
													<div style={{ flex: 1, marginLeft: "20px" }}>
														<TextFieldValue style={{ textAlign: "right" }}>{timeString}</TextFieldValue>
													</div>
												</BlockGroup>
											);
										})
									) : (
										<TextFieldValue>-</TextFieldValue>
									)}
								</Block>
							</ContentLeft>
							<ContentRight>
								<Block>
									<TextFieldLabel>課程類別</TextFieldLabel>
									{course && course.category && course.category.length > 0 ? (
										<CategoryGroup>
											{course.category.map((item, index) => {
												const itemText = item.trim();
												return (
													<div key={`${itemText}_${index}_category_item`} style={{ marginRight: "5px", marginBottom: "5px" }}>
														<CategoryButton label={itemText} />
													</div>
												);
											})}
										</CategoryGroup>
									) : (
										<TextFieldValue>-</TextFieldValue>
									)}
								</Block>
								<Block>
									<TextFieldLabel>課程頁面照</TextFieldLabel>
									{course && course.coverPhoto && course.coverPhoto.length > 0 ? (
										<div>
											<CourseImageUploader
												src={course.coverPhoto.length > 0 ? course.coverPhoto[0] : ""}
												isCover={true}
												isDisplayOnly={true}
											/>
										</div>
									) : (
										<TextFieldValue>-</TextFieldValue>
									)}
								</Block>
								<Block>
									<TextFieldLabel>課程相關照片/資料/小册子</TextFieldLabel>
									{course && course.coursePhoto && course.coursePhoto.length > 0 ? (
										<ScrollView>
											<BlockGroup>
												{course.coursePhoto.map((photo, index) => {
													return (
														<div
															key={`image_${index}`}
															style={{
																marginRight: "20px",
																width: "80px",
																height: "80px",
															}}
														>
															<CourseImageUploader src={photo} isDisplayOnly={true} />
														</div>
													);
												})}
											</BlockGroup>
										</ScrollView>
									) : (
										<TextFieldValue>-</TextFieldValue>
									)}
								</Block>
								<RowInBlock style={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
									<CancelButton label={"返回"} style={{ marginRight: "15px" }} handleOnClick={handleBack} />
								</RowInBlock>
							</ContentRight>
						</ContentColumnsContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
			<BlockMobileOverlay />
		</React.Fragment>
	);
};

const RowInBlock = styled.div`
	padding-top: 30px;
	display: flex;
	flex-direction: row;
`;

const RowInSearchBlock = styled.div`
	display: flex;
	flex-direction: row;
`;

const ContentColumnsContainer = styled.div`
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		flex-direction: row;
		display: flex;
	}
`;

const ContentLeft = styled.div`
	flex: 1;
	padding: 10px;
	border-right: 1px solid #dddddd;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		border-bottom: 1px #cccccc solid;
	}
`;

const ContentRight = styled.div`
	flex: 1;
	padding: 10px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		border-bottom: 1px #cccccc solid;
	}
`;

const ScrollView = styled.div`
	display: flex;
	overflow-x: scroll;
	width: 300px;
	padding-left: 5px;
	padding-top: 20px;
	padding-bottom: 10px;
`;

const BlockGroup = styled.div`
	display: flex;
	flex-direction: row;
`;

const Block = styled.div`
	padding: 10px;
	flex: ${(props) => props.flex || 1};
`;

const CategoryGroup = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const TextFieldLabel = styled.div`
	color: #000000;
	font-size: 13px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 7px;
`;

const TextFieldValue = styled.div`
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.46;
	letter-spacing: normal;
	text-align: left;
	color: #333333;
`;

const Title = styled.div`
	font-weight: bold;
	margin: 0 0 12px 4px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
	width: 100%;
`;

const SubTitle = styled.div`
	color: #666666;
	font-size: 12px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
`;
