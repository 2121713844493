import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { MOBILE_MAX_WIDTH } from "_constants";

import { learningCoursesConstants, generalConstants } from "_constants";
import { learningCoursesAction } from "actions";
import moment from "moment";
import _ from "lodash";
import isEqual from "react-fast-compare";
import { useHistory } from "react-router-dom";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { Loading, Pagination, RefreshButton, BlockMobileOverlay } from "components/general";
import { StandardSelect, StandardTextField } from "components/general/standard";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
	PaginationContainer,
	TableContainer,
	TableRow,
	TableTh,
	THButton,
	TableTd,
	Table,
	TableHeaderRow,
} from "components/layout";
import { Desktop } from "components/responsive";

import { ReactComponent as CourseIcon } from "resources/img/course_icon.svg";

const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 1;
const FROM_COURSE = "course";
export const CourseManagementPage = () => {
	const { navigationObject } = useSelector(
		({ course }) => ({
			navigationObject: course.navigationObject?.root,
		}),
		isEqual
	);

	const { learningCoursesList } = useSelector(
		({ course }) => ({
			learningCoursesList: course.navigationObject?.root?.learningCoursesList,
		}),
		isEqual
	);

	const totalNumOfRecord = useSelector(({ course }) => course.navigationObject?.root?.totalNumOfRecord);
	const currentPage = useSelector(({ course }) => course.navigationObject?.root?.currentPage);
	const numOfPage = useSelector(({ course }) => course.navigationObject?.root?.numOfPage);
	const sortingKey = useSelector(({ course }) => course.navigationObject?.root?.sortingKey);

	const isFetching = useSelector(({ course }) => course.isFetching);
	const loggedIn = useSelector(({ auth }) => auth.loggedIn);

	const [filterDistrict, setFilterDistrict] = useState(navigationObject?.district);
	const [filterCategory, setFilterCategory] = useState(navigationObject?.category);
	const [searchWord, setSearchWord] = useState(navigationObject?.searchKey);
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection);

	const timeoutRef = useRef(null);

	const dispatch = useDispatch();
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(0.5 * 1000));
	const reactHistory = useHistory();

	const handleSorting = (key) => {
		let direction = "DESC";
		if (sortingKey === key && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingDirection(direction);

		dispatch(
			learningCoursesAction.getLearningCourses({
				navigationKey: "root",
				search: searchWord || "",
				district: (filterDistrict || "") === "online" ? "" : filterDistrict,
				category: filterCategory,
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: `${key}:${direction}`,
				isOnline: filterDistrict === "online",
			})
		);
	};

	const handlePageChange = (pageNum) => {
		dispatch(
			learningCoursesAction.getLearningCourses({
				navigationKey: "root",
				search: searchWord || "",
				district: (filterDistrict || "") === "online" ? "" : filterDistrict,
				category: filterCategory || "",
				l: DEFAULT_LIMIT,
				p: pageNum,
				sort: `${sortingKey}:${sortingDirection}`,
				isOnline: filterDistrict === "online",
			})
		);
	};

	useEffect(() => {
		if (loggedIn) {
			dispatch(
				learningCoursesAction.getLearningCourses({
					navigationKey: "root",
					search: navigationObject?.searchKey,
					district: (navigationObject?.district || "") === "online" ? "" : navigationObject?.district,
					category: navigationObject?.category,
					l: DEFAULT_LIMIT,
					p: currentPage,
					sort: `${navigationObject?.sortingKey}:${navigationObject?.sortingDirection}`,
					isOnline: navigationObject?.district === "online",
				})
			);
		}
	}, [dispatch, loggedIn]);

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const handleSelectChange = ({ key, value }) => {
		let searchingWord = false;
		let useDefaultKey = false;

		if (key === "district") {
			setFilterDistrict(value);
		} else if (key === "category") {
			setFilterCategory(value);
		} else if (key === "searchWord") {
			if (searchWord && searchWord.trim().length > 0 && _.isEmpty(value)) {
				useDefaultKey = true;
			}
			setSearchWord(value);
			searchingWord = !_.isEmpty(value);
		}

		if (!debouncer.token()) {
			resetTimeout();
			timeoutRef.current = setTimeout(() => {
				dispatch(
					learningCoursesAction.getLearningCourses({
						navigationKey: "root",
						search: key === "searchWord" ? value : searchWord,
						district: key === "district" ? (value === "online" ? "" : value) : filterDistrict === "online" ? "" : filterDistrict,
						category: key === "category" ? value : filterCategory,
						l: DEFAULT_LIMIT,
						p: DEFAULT_PAGE,
						sort: useDefaultKey ? "name:ASC" : searchingWord ? "score:DESC" : `${sortingKey}:${sortingDirection}`,
						isOnline: (key === "district" && value === "online") || filterDistrict === "online",
					})
				);
			}, 1000);
			return;
		}

		resetTimeout();
		dispatch(
			learningCoursesAction.getLearningCourses({
				navigationKey: "root",
				search: key === "searchWord" ? value : searchWord,
				district: key === "district" ? (value === "online" ? "" : value) : filterDistrict === "online" ? "" : filterDistrict,
				category: key === "category" ? value : filterCategory,
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: useDefaultKey ? "name:ASC" : searchingWord ? "score:DESC" : `${sortingKey}:${sortingDirection}`,
				isOnline: (key === "district" && value === "online") || filterDistrict === "online",
			})
		);
	};

	const handleCourseDetails = (item) => {
		reactHistory.push({
			pathname: `${generalConstants.PATH.LEARNING_COURSE}`,
			state: { from: FROM_COURSE, sourceCourse: item },
		});
	};

	const getDistrict = (item) => {
		if (item === "") {
			return "/";
		}
		return learningCoursesConstants.DATA.district[item].label;
	};

	const handleRefreshFilter = () => {
		setFilterCategory("");
		setFilterDistrict("");
		setSearchWord("");
		dispatch(
			learningCoursesAction.getLearningCourses({
				navigationKey: "root",
				search: "",
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: sortingKey === "score" ? "name:ASC" : `${sortingKey}:${sortingDirection}`,
			})
		);
	};

	const renderTH = () => {
		return learningCoursesConstants.MANAGMENT_TABLE_COLUMN.map((column) => {
			return (
				<StyledTableTh className={column.key} key={`${column.key}_table_header`}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSorting(column.key)}>
						<div>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</StyledTableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (learningCoursesList && learningCoursesList.length > 0) {
			return learningCoursesList.map((msg, index) => {
				const tempObj = {};
				learningCoursesConstants.MANAGMENT_TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = msg[column.key];
				});

				return (
					<TableRow
						key={`${msg._id}_${index}_tr`}
						onClick={() => {
							handleCourseDetails(msg);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "name") {
								var courseName = "";
								if (typeof tempObj[columnName] != "undefined") {
									courseName = tempObj[columnName];

									if (_.isEmpty(courseName)) {
										courseName = msg["nameEng"];
									}
								}
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{msg.status === learningCoursesConstants.DATA.status.CLOSED.key && (
											<ClosedBadge>{learningCoursesConstants.DATA.status[msg.status].label}</ClosedBadge>
										)}
										{(msg.status === learningCoursesConstants.DATA.status.SUSPENDED.key ||
											msg.status === learningCoursesConstants.DATA.status.CANCELLED.key) && (
											<PendingBadge>{learningCoursesConstants.DATA.status[msg.status].label}</PendingBadge>
										)}
										{courseName}
									</StyledTableTd>
								);
							} else if (columnName === "district") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{msg.isOnline ? "網上課程" : getDistrict(tempObj[columnName] || "")}
									</StyledTableTd>
								);
							} else if (columnName === "age") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{typeof msg.minAge != "undefined" && typeof msg.maxAge != "undefined"
											? `${msg.minAge}歲 - ${msg.maxAge}歲`
											: typeof msg.minAge != "undefined"
											? `${msg.minAge}歲以上`
											: typeof msg.maxAge != "undefined"
											? `${msg.maxAge}歲以下`
											: `-`}
									</StyledTableTd>
								);
							} else if (columnName === "category") {
								var categoryName = "-";
								if (typeof tempObj[columnName] != "undefined" && typeof tempObj[columnName] != "string") {
									tempObj[columnName].forEach((name, index) => {
										if (index === 0) {
											categoryName = `${name}`;
										} else {
											categoryName = `${categoryName}、${name}`;
										}
									});
								}
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{categoryName}
									</StyledTableTd>
								);
							} else if (columnName === "learningCenterName") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{tempObj[columnName]}
									</StyledTableTd>
								);
							} else if (columnName === "lastUpdateTime") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
									</StyledTableTd>
								);
							}

							return (
								<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
									{tempObj[columnName]}
								</StyledTableTd>
							);
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();
	return (
		<React.Fragment>
			{!!isFetching && <Loading />}
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.LEARNING_COURSE_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<AdminSectionbar
						title={generalConstants.NAV_TAB.LEARNING_COURSE_MANAGEMENT.key}
						sublabel={`課程數量: ${totalNumOfRecord}`}
						buttonLabel={"新增課程"}
						buttonIcon={<CourseIcon />}
						buttonType={generalConstants.BUTTON_TYPE.SUBMIT}
						buttonPath={{
							path: `${generalConstants.PATH.LEARNING_COURSE}/add`,
							stateObj: { from: FROM_COURSE },
						}}
						locationPathArray={[
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.LEARNING_COURSE_MANAGEMENT.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<SearchBar>
							<SearchBarLeft>
								<RowInBlock marginBottom="0px">
									<div style={{ marginLeft: "10px", width: "20%" }}>
										<StandardSelect
											emptyText={"上課地點"}
											name={"district"}
											displayEmpty
											options={[{ key: "online", value: "網上課程" }, ...learningCoursesConstants.OPTION.district]}
											value={filterDistrict}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ marginLeft: "10px", width: "20%" }}>
										<StandardSelect
											emptyText={"課程類別"}
											name={"category"}
											displayEmpty
											options={learningCoursesConstants.OPTION.category}
											value={filterCategory}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ marginLeft: "10px", width: "40%" }}>
										<StandardTextField
											placeholder="關鍵字"
											value={searchWord}
											name="searchWord"
											handleChange={handleSelectChange}
											style={{
												borderTopRightRadius: "0px",
												borderBottomRightRadius: "0px",
											}}
										/>
									</div>
									<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
								</RowInBlock>
							</SearchBarLeft>
							<Desktop>
								<SearchBarRight />
							</Desktop>
						</SearchBar>
						<TableContainer>
							<Table>
								<thead>
									<TableHeaderRow>{renderTableHeader}</TableHeaderRow>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</Table>
						</TableContainer>
						<PaginationContainer>
							<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
						</PaginationContainer>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
			<BlockMobileOverlay />
		</React.Fragment>
	);
};

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const ClosedBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;

const PendingBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #ffd95c;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: black;
`;

const StyledTableTh = styled(TableTh)`
	&.name {
		width: 25%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 0;
			z-index: 2;
			position: sticky;
			width: calc(30vw);
		}
	}
	&.district {
		width: 10%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(20vw);
		}
	}
	&.age {
		width: 15%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(20vw);
		}
	}
	&.category {
		width: 20%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(30vw);
		}
	}

	&.learningCenterName {
		width: 15%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(30vw);
		}
	}

	&.lastUpdateTime {
		width: 170px;
	}
`;

const StyledTableTd = styled(TableTd)`
	background-color: #fff;
	&.name {
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 0;
			z-index: 2;
			position: sticky;
			width: calc(30vw);
		}
	}
`;
