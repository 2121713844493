import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { toast } from "react-toastify";
import { generalConstants, learningCoursesConstants, learningCentreConstants, DESKTOP_MIN_WIDTH } from "_constants";
import { learningCoursesAction, learningCentreAction } from "actions";
import { learningCentreService } from "services";
import moment from "moment";
import _ from "lodash";
import { history, AvatarHelper } from "_util/_helper";
import isEqual from "react-fast-compare";
import { MOBILE_MAX_WIDTH } from "_constants";

import {
	Loading,
	CategoryButton,
	Pagination,
	BlockMobileOverlay,
	WhatsAppButton,
	FacebookButton,
	IGButton,
	AlertDialog,
} from "components/general";
import { useLocation, Link, useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { ReactComponent as CourseIcon } from "resources/img/course_icon.svg";
import BinIcon from "resources/img/bin.svg";

import editIcon from "resources/img/school_inner_pencil.svg";
import backIcon from "resources/img/backet_arrow.svg";
import cloneIcon from "resources/img/clone_icon.png";
import { Desktop, desktop, Mobile } from "components/responsive";

import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	PaginationContainer,
	TableContainer,
	Table,
	TableHeaderRow,
	TableRow,
	TableTh,
	THButton,
	TableTd,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
} from "components/layout";

const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 1;
export const LearningCentreDetailScreen = () => {
	const location = useLocation();

	const [prevPage, setPrevPage] = useState(location?.state?.from);
	const [key, setLearningCenterKey] = useState(location?.state?.sourceCenter?._key);
	const [sourceCenter, setSourceCenter] = useState(location?.state?.sourceCenter);
	const [isDeleteAlertShow, setIsDeleteAlertShow] = useState(false);

	const { navigationObject } = useSelector(
		({ course }) => ({
			navigationObject: course.navigationObject?.center,
		}),
		isEqual
	);
	const { learningCoursesList } = useSelector(
		({ course }) => ({
			learningCoursesList: course.navigationObject?.center?.learningCoursesList,
		}),
		isEqual
	);
	// const centre = useSelector(({ centre }) => centre.centre);
	const isCenterFetching = useSelector(({ centre }) => centre.isFetching);
	const isCourseFetching = useSelector(({ course }) => course.isFetching);
	const currentPage = useSelector(({ course }) => course.navigationObject?.center?.currentPage);
	const numOfPage = useSelector(({ course }) => course.navigationObject?.center?.numOfPage);
	const loggedIn = useSelector(({ auth }) => auth.loggedIn);
	const deleteLearningCentreSuccess = useSelector(({ centre }) => centre.deleteLearningCentreSuccess);
	const deleteInProgress = useSelector(({ centre }) => centre.deleteInProgress);
	const initForm = {
		key: "",
		createdBy: "",
		createdTime: "",
		category: [],
		name: "",
		nameEng: "",
		description: "",
		status: "",
		profilePic: "",
		address: "",
		district: "",
		openingHours: "",
		email: "",
		phone: "",
		website: "",
		whatsapp: "",
		ig: "",
		facebook: "",
	};

	const [center, setCenter] = useState({});
	const [formData, setFormData] = useState(initForm);
	const [label, setLabel] = useState("");
	const [sortingKey, setSortingKey] = useState(navigationObject?.sortingKey);
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection);
	const [isLoading, setIsLoading] = useState(false);
	const [showCenterInfo, setShowCenterInfo] = useState(false);

	const dispatch = useDispatch();
	const reactHistory = useHistory();

	useEffect(() => {
		setPrevPage(location?.state?.from);
		setSourceCenter(location?.state.sourceCenter);
		setLearningCenterKey(location?.state.sourceCenter?._key);
	}, [location?.state]);

	useEffect(() => {
		if (loggedIn) {
			if (key) {
				getCenterByKey(false);
			}
			if (navigationObject?.learningCenterKey === key) {
				dispatch(
					learningCoursesAction.getLearningCourses({
						navigationKey: "center",
						learningCenterKey: key,
						sort: `${navigationObject?.sortingKey}:${navigationObject?.sortingDirection}`,
						l: DEFAULT_LIMIT,
						p: currentPage,
					})
				);
			} else {
				setSortingKey("name");
				setSortingDirection("ASC");
				dispatch(
					learningCoursesAction.getLearningCourses({
						navigationKey: "center",
						learningCenterKey: key,
						sort: "name:ASC",
						l: DEFAULT_LIMIT,
						p: DEFAULT_PAGE,
					})
				);
			}
		}
	}, [dispatch, loggedIn]);

	useEffect(() => {
		if (!deleteInProgress && deleteLearningCentreSuccess) {
			dispatch({ type: learningCentreConstants.type.RESET_LEARNING_CENTRE_SUCCESS });
			toast.success("已成功刪除");
			history.goBack();
		} else if (!deleteInProgress && isDeleteAlertShow) {
			setIsDeleteAlertShow(false);
		}
	}, [deleteLearningCentreSuccess, deleteInProgress]);

	useEffect(() => {
		if (!loggedIn) {
			return;
		}
		if (center && !_.isEmpty(center) && sourceCenter && !_.isEmpty(sourceCenter)) {
			if (moment(sourceCenter.lastUpdateTime).isAfter(center.lastUpdateTime)) {
				getCenterByKey(true);
				return;
			}
		}
		if (center || sourceCenter) {
			const currentCenter = !_.isEmpty(center) ? center : !_.isEmpty(sourceCenter) ? sourceCenter : null;
			if (currentCenter != null) {
				const data = {
					key: currentCenter._key,
					createdBy: currentCenter.createBy,
					createdTime: currentCenter.createdTime,
					category: currentCenter.category || [],
					name: currentCenter.name ? currentCenter.name : "",
					nameEng: currentCenter.nameEng ? currentCenter.nameEng : "",
					description: currentCenter.description || "",
					status: currentCenter.status || "",
					profilePic: currentCenter.profilePic || "",
					address: currentCenter.address || "",
					district: currentCenter.district || "",
					openingHours: currentCenter.openingHours || "",
					email: currentCenter.email || "",
					phone: currentCenter.phone || "",
					website: currentCenter.website || "",
					whatsapp: currentCenter.whatsapp || "",
					ig: currentCenter.ig || "",
					facebook: currentCenter.facebook || "",
				};
				setFormData(data);
				setLabel(_.isEmpty(data.name) ? data.nameEng || "" : data.name);
			}
		}
	}, [center, loggedIn]);

	const getCenterByKey = (forceUpdate) => {
		setIsLoading(true);
		learningCentreService.getLearningCenterByKeyWithoutData(key).then(
			(response) => {
				setIsLoading(false);
				if (response) {
					setCenter(response);
					if (forceUpdate) {
						setSourceCenter(response);
					}
				}
			},
			(error) => {
				setIsLoading(false);
				history.goBack();
				toast.error(error.error);
			}
		);
	};

	const handleSorting = (sort) => {
		let direction = "DESC";
		if (sortingKey === sort && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingKey(sort);
		setSortingDirection(direction);

		dispatch(
			learningCoursesAction.getLearningCourses({
				navigationKey: "center",
				learningCenterKey: key,
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: `${sort}:${direction}`,
			})
		);
	};

	const handlePageChange = (pageNum) => {
		dispatch(
			learningCoursesAction.getLearningCourses({
				navigationKey: "center",
				search: "",
				learningCenterKey: key,
				l: DEFAULT_LIMIT,
				p: pageNum,
				sort: `${sortingKey}:${sortingDirection}`,
			})
		);
	};

	const getDistrict = (item) => {
		if (item === "") {
			return "/";
		}
		return learningCoursesConstants.DATA.district[item].label;
	};

	const getText = (text) => {
		return text ? text.replace(/\n/g, "<br />") : "";
	};

	const handleCloseDeleteAlert = () => {
		setIsDeleteAlertShow(false);
	};

	const renderTH = () => {
		return learningCoursesConstants.TABLE_COLUMN.map((column) => {
			return (
				<StyledTableTh className={column.key} key={`${column.key}_table_header`}>
					<THButton
						key={`${column.key}_table_header`}
						type="button"
						onClick={() => {
							handleSorting(column.key);
						}}
					>
						<div>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</StyledTableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (learningCoursesList && learningCoursesList.length > 0) {
			return learningCoursesList.map((msg, index) => {
				const tempObj = {};
				learningCoursesConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = msg[column.key];
				});

				return (
					<TableRow
						key={`${msg._id}_${index}_tr`}
						onClick={() => {
							handleCourseDetails(msg);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "name") {
								var courseName = "";
								if (typeof tempObj[columnName] != "undefined") {
									courseName = tempObj[columnName];

									if (_.isEmpty(courseName)) {
										courseName = msg["nameEng"];
									}
								}
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{msg.status === learningCoursesConstants.DATA.status.CLOSED.key && (
											<ClosedBadge>{learningCoursesConstants.DATA.status[msg.status].label}</ClosedBadge>
										)}
										{(msg.status === learningCoursesConstants.DATA.status.SUSPENDED.key ||
											msg.status === learningCoursesConstants.DATA.status.CANCELLED.key) && (
											<PendingBadge>{learningCoursesConstants.DATA.status[msg.status].label}</PendingBadge>
										)}
										{courseName}
									</StyledTableTd>
								);
							} else if (columnName === "district") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{msg.isOnline ? "網上課程" : getDistrict(tempObj[columnName] || "")}
									</StyledTableTd>
								);
							} else if (columnName === "age") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{typeof msg.minAge != "undefined" && typeof msg.maxAge != "undefined"
											? `${msg.minAge}歲 - ${msg.maxAge}歲`
											: typeof msg.minAge != "undefined"
											? `${msg.minAge}歲以上`
											: typeof msg.maxAge != "undefined"
											? `${msg.maxAge}歲以下`
											: `-`}
									</StyledTableTd>
								);
							} else if (columnName === "category") {
								var categoryName = "-";
								if (typeof tempObj[columnName] != "undefined" && typeof tempObj[columnName] != "string") {
									tempObj[columnName].forEach((name, index) => {
										if (index === 0) {
											categoryName = `${name}`;
										} else {
											categoryName = `${categoryName}、${name}`;
										}
									});
								}
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{categoryName}
									</StyledTableTd>
								);
							} else if (columnName === "lastUpdateTime") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
									</StyledTableTd>
								);
							}
							return (
								<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
									{tempObj[columnName]}
								</StyledTableTd>
							);
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	const handleCourseDetails = (item) => {
		reactHistory.push({
			pathname: `${generalConstants.PATH.LEARNING_CENTER}/course`,
			state: { from: prevPage, sourceCenter: center, sourceCourse: item },
		});
	};

	const handleDelete = () => {
		const data = {
			key: center?._key,
			rev: center?._rev,
		};

		dispatch(learningCentreAction.deleteLearningCenter(data));
	};

	const sectionBar = () => {
		return (
			<AdminSectionbar
				title={generalConstants.NAV_TAB.LEARNING_CENTRE_DETAIL.key}
				label={label}
				buttonLabel={"新增課程"}
				buttonIcon={<CourseIcon />}
				buttonType={generalConstants.BUTTON_TYPE.SUBMIT}
				buttonPath={{
					path: `${generalConstants.PATH.LEARNING_CENTER}/course/add`,
					stateObj: { from: prevPage, sourceCenter: center },
				}}
				locationPathArray={[
					{
						title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key],
						isAction: true,
						target: generalConstants.PATH.LEARNING_CENTRE_MANAGEMENT,
					},
					{ title: label, isAction: false, target: "" },
				]}
			/>
		);
	};

	return (
		<React.Fragment>
			{(!!isCenterFetching || !!isCourseFetching || !!isLoading) && <Loading />}
			<AlertDialog
				active={isDeleteAlertShow}
				handleOnClose={handleCloseDeleteAlert}
				handleOnYes={handleDelete}
				title={"確定移除補習社？"}
			/>
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<ContentContainer>
						<Mobile>
							{sectionBar()}
							<CenterInfoViewButton onClick={() => setShowCenterInfo(!showCenterInfo)}>
								<CenterInfoViewButtonText>補習社資料</CenterInfoViewButtonText>
								<ForwardIcon alt="" src={backIcon} />
							</CenterInfoViewButton>
						</Mobile>
						<LeftPageContainer>
							<Desktop>{sectionBar()}</Desktop>
							<Paper elevation={desktop() ? 3 : 0}>
								<ContentLeft>
									<TableContainer>
										<Table>
											<thead>
												<TableHeaderRow>{renderTableHeader}</TableHeaderRow>
											</thead>
											<tbody>{renderTableCell}</tbody>
										</Table>
									</TableContainer>
									<PaginationContainer>
										<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
									</PaginationContainer>
								</ContentLeft>
							</Paper>
						</LeftPageContainer>
						<ContentRight active={showCenterInfo}>
							<Paper elevation={desktop() ? 3 : 0}>
								<CenterInfoContainer>
									<CenterInfoBackBar>
										<Mobile>
											<IconButton aria-label="返回" onClick={() => setShowCenterInfo(false)} style={{ opacity: "0.6", padding: "5px 5px" }}>
												<BackIcon alt="" src={backIcon} />
											</IconButton>
										</Mobile>
										<TextFieldLabel>- 補習社資料</TextFieldLabel>
										<Centerbuttons>
											<Link
												style={{ textDecoration: "none" }}
												to={{ pathname: `${generalConstants.PATH.LEARNING_CENTER}/edit`, state: { from: prevPage, sourceCenter: center } }}
											>
												<IconButton aria-label="編輯" style={{ float: "right", padding: "5px" }}>
													<img alt="" src={editIcon} />
												</IconButton>
											</Link>
											<IconButton aria-label="刪除" onClick={() => setIsDeleteAlertShow(true)} style={{ float: "right", padding: "5px" }}>
												<img alt="" src={BinIcon} />
											</IconButton>
											<Link
												style={{ textDecoration: "none" }}
												to={{
													pathname: `${generalConstants.PATH.LEARNING_CENTER}/add`,
													state: { from: prevPage, cloneCenter: center },
												}}
											>
												<IconButton aria-label="複製" style={{ float: "right", padding: "5px" }}>
													<img alt="" src={cloneIcon} />
												</IconButton>
											</Link>
										</Centerbuttons>
									</CenterInfoBackBar>
									{formData.profilePic && formData.profilePic !== "" && formData.profilePic !== "{}" && (
										<Avatar showBadge={false} src={AvatarHelper(formData.profilePic)} size={45} type={"parent"} />
									)}
									{formData.status === learningCentreConstants.DATA.status.CLOSED.key && (
										<DetailClosedBadge>{learningCentreConstants.DATA.status[formData.status].label}</DetailClosedBadge>
									)}
									{formData.name && <CenterNameLabel>{formData.name}</CenterNameLabel>}
									{formData.nameEng && <CenterNameLabel>{formData.nameEng}</CenterNameLabel>}

									<SmallRowInBlock>
										<CenterSmalllDetaiLabel style={{ flex: 3 }}>地址</CenterSmalllDetaiLabel>
										<CenterSmalllDetaiLabel style={{ flex: 1 }}>:</CenterSmalllDetaiLabel>
										<CenterSmalllDetaiLabel style={{ flex: 17 }}>{formData.address || "-"}</CenterSmalllDetaiLabel>
									</SmallRowInBlock>
									<SmallRowInBlock>
										<CenterSmalllDetaiLabel style={{ flex: 3 }}>電話</CenterSmalllDetaiLabel>
										<CenterSmalllDetaiLabel style={{ flex: 1 }}>:</CenterSmalllDetaiLabel>
										<CenterSmalllDetaiLabel style={{ flex: 17 }}>{formData.phone || "-"}</CenterSmalllDetaiLabel>
									</SmallRowInBlock>
									<SmallRowInBlock>
										<CenterSmalllDetaiLabel style={{ flex: 3 }}>電郵</CenterSmalllDetaiLabel>
										<CenterSmalllDetaiLabel style={{ flex: 1 }}>:</CenterSmalllDetaiLabel>
										{formData.email ? (
											<CenterSmalllDetaiLabel style={{ flex: 17 }}>
												<a target="_black" href={`mailto:${formData.email}`}>
													{formData.email}
												</a>
											</CenterSmalllDetaiLabel>
										) : (
											<CenterSmalllDetaiLabel style={{ flex: 17 }}>{"-"}</CenterSmalllDetaiLabel>
										)}
									</SmallRowInBlock>
									<SmallRowInBlock>
										<CenterSmalllDetaiLabel style={{ flex: 3 }}>網址</CenterSmalllDetaiLabel>
										<CenterSmalllDetaiLabel style={{ flex: 1 }}>:</CenterSmalllDetaiLabel>

										{formData.website ? (
											<CenterSmalllDetaiLabel style={{ flex: 17 }}>
												<a
													target="_black"
													href={
														formData.website.includes("http://") || formData.website.includes("https://")
															? formData.website
															: `http://${formData.website}`
													}
												>
													{formData.website || "-"}
												</a>
											</CenterSmalllDetaiLabel>
										) : (
											<CenterSmalllDetaiLabel style={{ flex: 17 }}>{"-"}</CenterSmalllDetaiLabel>
										)}
									</SmallRowInBlock>
									{(!!formData.facebook || !!formData.ig || !!formData.whatsapp) && (
										<SmallRowInBlock style={{ marginTop: "10px", marginBottom: "10px" }}>
											{!!formData.facebook && <FacebookButton value={formData.facebook} />}
											{!!formData.ig && <IGButton value={formData.ig} />}
											{!!formData.whatsapp && <WhatsAppButton value={formData.whatsapp} />}
										</SmallRowInBlock>
									)}
									<div>
										<SubTitle style={{ flex: 3 }}>營業時間</SubTitle>
										<SubText
											style={{ flex: 17 }}
											dangerouslySetInnerHTML={{
												__html: getText(formData.openingHours) || "-",
											}}
										/>
									</div>
									<div>
										<SubTitle style={{ flex: 3 }}>補習社簡介</SubTitle>
										<SubText
											style={{ flex: 17 }}
											dangerouslySetInnerHTML={{
												__html: getText(formData.description) || "-",
											}}
										/>
									</div>
									<div>
										<SubTitle style={{ flex: 3 }}>課程類別</SubTitle>
										{formData.category.length > 0 ? (
											<CategoryGroup>
												{formData.category.map((item, index) => {
													const itemText = item.trim();
													return (
														<div
															key={`${itemText}_${index}_category_item`}
															style={{
																marginRight: "5px",
																marginBottom: "5px",
															}}
														>
															<CategoryButton label={itemText} />
														</div>
													);
												})}
											</CategoryGroup>
										) : (
											<SubText style={{ flex: 17 }}>{"-"}</SubText>
										)}
									</div>
									<div style={{ height: "16px" }}></div>
								</CenterInfoContainer>
							</Paper>
						</ContentRight>
					</ContentContainer>
					<AdminFooter></AdminFooter>
				</RightContainer>
			</PageContainer>
			<BlockMobileOverlay />
		</React.Fragment>
	);
};

const ContentRight = styled.div`
	flex: 6;
	padding: 0;
	margin-top: 20px;
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		margin-left: 20px;
	}
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		position: fixed;
		transition: all 0.3s;
		margin-top: 0;
		left: ${(props) => (props.active ? "0" : "100%")};
		font-size: 1.8em;
		z-index: 12;
		top: 0;
		background-color: #fff;
		width: 100%;
		height: 100%;

		overflow-y: scroll;
	}
`;

const LeftPageContainer = styled.div`
	flex: 16;
`;

const ContentLeft = styled.div`
	flex: 18;
	height: 580px;
	border-right: 1px solid #dddddd;
`;

const CenterInfoContainer = styled.div`
	flex: 1;
	height: 590px;
	margin-left: 20px;
	margin-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		height: 100%;
	}
`;

const ForwardIcon = styled.img`
	transform: rotate(270deg);
	width: 13px;
	height: 8px;
	align-self: center;
	flex: 0 0 8px;
`;

const BackIcon = styled.img`
	transform: rotate(90deg);
	height: 10px;
`;

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const ContentContainer = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
	padding-left: 20px;
	padding-right: 20px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex-direction: column;
		padding-left: 0px;
		padding-right: 0px;
	}
`;

const CenterInfoViewButton = styled.div`
	display: flex;
	margin: 0 15px 15px 15px;
	padding: 16px 10px 16px 12px;
	border-radius: 3px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
	background-color: #fff;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.43;
	color: #333399;
`;

const CenterInfoViewButtonText = styled.div`
	flex: 1;
`;

const CenterInfoBackBar = styled.div`
	display: flex;

	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		position: sticky;
		top: 0;
		width: 100%;
		margin-bottom: 10px;
	}
`;

const Centerbuttons = styled.div`
	text-align: right;
`;

const TextFieldLabel = styled.div`
	color: #333399;
	font-size: 14px;
	line-height: 1.5;
	font-weight: bold;
	margin-bottom: 0px;
	flex: 1;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 20px;
		text-align: center;
	}
`;

const Avatar = styled.img`
	border-radius: 50%;
	max-width: 80px;
	max-height: 80px;
	border: #aaaaaa 1px solid;
`;

const CenterNameLabel = styled.div`
	color: #000000;
	font-size: 14px;
	line-height: 1;
	font-weight: bold;
	margin-bottom: 4px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 18px;
	}
`;

const SmallRowInBlock = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 4px;
	margin-top: 4px;
`;

const CenterSmalllDetaiLabel = styled.div`
	color: #666666;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;

	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 16px;
	}
`;

const Title = styled.div`
	font-weight: bold;
	margin: 0 0 12px 4px;
	font-size: 14px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
	width: 100%;
`;

const SubTitle = styled.div`
	color: #000000;
	font-size: 11px;
	line-height: 1;
	font-weight: bold;
	margin-bottom: 8px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 14px;
	}
`;

const SubText = styled.div`
	color: #333333;
	font-size: 11px;
	line-height: 1;
	margin-bottom: 8px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 14px;
	}
`;

const CategoryGroup = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const ClosedBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;

const DetailClosedBadge = styled.span`
	margin: 8px 0 40px 5px;
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
	float: right;
`;

const PendingBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #ffd95c;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: black;
`;

const StyledTableTh = styled(TableTh)`
	&.name {
		width: 30%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 0;
			z-index: 2;
			position: sticky;
			width: calc(30vw);
		}
	}
	&.district {
		width: 15%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(20vw);
		}
	}
	&.age {
		width: 15%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(20vw);
		}
	}
	&.category {
		width: 20%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(30vw);
		}
	}
	&.lastUpdateTime {
		width: 170px;
	}
`;

const StyledTableTd = styled(TableTd)`
	background-color: #fff;
	&.name {
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 0;
			z-index: 2;
			position: sticky;
			width: calc(30vw);
		}
	}
`;
