import React from "react";
import { MatchMedia } from "components/responsive";
import { MOBILE_MAX_WIDTH } from "_constants";
import PropTypes from "prop-types";


export const QUERY = `(max-width: ${MOBILE_MAX_WIDTH})`;

export const Mobile = (props) => {
  const { children } = props;
  return <MatchMedia query={QUERY}>{children}</MatchMedia>;
}


export function mobile() {
  const match = window.matchMedia(QUERY);
  return match.matches;
}

Mobile.propTypes= {
  children: PropTypes.any
};


Mobile.defaultProps = {
	children: undefined
};
