import { generalConstants, learningCoursesConstants } from "_constants";
import { learningCoursesService } from "services";
import { toast } from "react-toastify";
import { history } from "_util/_helper";

export const learningCoursesAction = {
	createLearningCourse,
	updateLearningCourse,
	getLearningCourses,
	getLearningCourseByKey,
	deleteLearningCourse,
};

function deleteLearningCourse(data) {
	return (dispatch) => {
		dispatch(request());
		learningCoursesService.deleteLearningCourse(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return { type: learningCoursesConstants.type.DELETE_LEARNING_COURSES_REQUEST };
	}
	function failure() {
		return { type: learningCoursesConstants.type.DELETE_LEARNING_COURSES_FAILURE };
	}
	function success() {
		return { type: learningCoursesConstants.type.DELETE_LEARNING_COURSES_SUCCESS };
	}
}

function createLearningCourse(data) {
	return (dispatch) => {
		dispatch(request());
		learningCoursesService.createLearningCourse(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				console.error("createLearningCourse ERROR:", error);
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return {
			type: learningCoursesConstants.type.CREATE_LEARNING_COURSES_REQUEST,
		};
	}
	function failure() {
		return {
			type: learningCoursesConstants.type.CREATE_LEARNING_COURSES_FAILURE,
		};
	}
	function success() {
		return {
			type: learningCoursesConstants.type.CREATE_LEARNING_COURSES_SUCCESS,
		};
	}
}

function updateLearningCourse(data) {
	return (dispatch) => {
		dispatch(request());
		learningCoursesService.updateLearningCourse(data).then(
			() => {
				dispatch(success());
			},
			(error) => {
				console.error("updateLearningCourse ERROR:", error);
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return {
			type: learningCoursesConstants.type.UPDATE_LEARNING_COURSES_REQUEST,
		};
	}
	function failure() {
		return {
			type: learningCoursesConstants.type.UPDATE_LEARNING_COURSES_FAILURE,
		};
	}
	function success() {
		return {
			type: learningCoursesConstants.type.UPDATE_LEARNING_COURSES_SUCCESS,
		};
	}
}

function getLearningCourses(data) {
	console.log("getLearningCourses form", data);

	return (dispatch) => {
		dispatch(request());
		learningCoursesService.getLearningCourses(data).then(
			(response) => {
				dispatch(success(response, data));
			},
			(error) => {
				console.error("getLearningCourses ERROR:", error);
				toast.error(error.error);
				dispatch(failure());
			}
		);
	};

	function request() {
		return {
			type: learningCoursesConstants.type.FETCH_LEARNING_COURSES_LIST_REQUEST,
		};
	}
	function failure() {
		return {
			type: learningCoursesConstants.type.FETCH_LEARNING_COURSES_LIST_FAILURE,
		};
	}
	function success(response, inputData) {
		const { totalItems, items } = response;

		let sortingKey = "name",
			sortingDirection = "ASC";
		const sortingArray = inputData?.sort?.split(":");
		if (sortingArray.length === 2) {
			sortingKey = sortingArray[0];
			sortingDirection = sortingArray[1] === "DESC" ? "DESC" : "ASC";
		}

		return {
			type: learningCoursesConstants.type.FETCH_LEARNING_COURSES_LIST_SUCCESS,
			navigationKey: inputData.navigationKey,
			totalNum: totalItems,
			totalPage: totalItems / 10 === parseInt(totalItems / 10) ? parseInt(totalItems / 10) : parseInt(totalItems / 10) + 1,
			currentPage: data.p,
			learningCoursesList: items,
			sortingDirection,
			sortingKey,
			searchKey: inputData.search || "",
			district: inputData.isOnline || false ? "online" : inputData.district || "",
			category: inputData.category || "",
			learningCenterKey: inputData.navigationKey === "center" ? inputData.learningCenterKey || "" : undefined,
		};
	}
}

function getLearningCourseByKey(key) {
	return (dispatch) => {
		dispatch(request());
		learningCoursesService.getLearningCourseByKey(key).then(
			(response) => {
				dispatch(success(response));
			},
			(error) => {
				toast.error(error.error);
				dispatch(failure());
				if (error.error?.status !== 401) {
					history.push(generalConstants.PATH.LEARNING_COURSE_MANAGEMENT);
				}
			}
		);
	};

	function request() {
		return { type: learningCoursesConstants.type.FETCH_COURSES_REQUEST };
	}
	function failure() {
		return { type: learningCoursesConstants.type.FETCH_COURSES_FAILURE };
	}
	function success(course) {
		return {
			type: learningCoursesConstants.type.FETCH_COURSES_SUCCESS,
			course,
		};
	}
}
