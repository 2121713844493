import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextField, YellowButton } from "components/general";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import headerLogo from "resources/img/header_logo@2x.png";
import titleImg from "resources/img/title.png";
import { MenuItem, Select } from "@material-ui/core";
import { MOBILE_MAX_WIDTH, DESKTOP_MIN_WIDTH } from "_constants";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

export const RegisterContainer = (props) => {
	const { handleRegister } = props;
	const recaptchaRef = useRef(null);
	const isFetching = useSelector(({ auth }) => auth.isFetching);

	const [formData, setFormData] = useState({
		name: "",
		email: "",
		address: "",
		phone: "",
		contactPerson: "",
		isMultiCenter: "false",
		numOfCenter: "1",
		whatsapp: "",
		website: "",
		facebook: "",
		type: "LEARNING_CENTER",
	});

	const handleOnClick = async () => {
		if (!formData.name) {
			toast.error("請填寫機構名稱");
			return;
		}
		if (
			!formData.email ||
			// eslint-disable-next-line no-useless-escape
			!/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
				formData.email
			)
		) {
			toast.error("請填寫有效的電郵地址");
			return;
		}
		if (!formData.phone) {
			toast.error("請填寫電話");
			return;
		}
		if (!formData.address) {
			toast.error("請填寫機構地址");
			return;
		}
		if (!formData.contactPerson) {
			toast.error("請填寫聯絡人名字");
			return;
		}

		if (formData.isMultiCenter === "true" && (!/^[0-9]*$/.test(formData.numOfCenter) || formData.numOfCenter < 1)) {
			toast.error("必須輸入正分校數");
		}
		const token = await recaptchaRef.current.executeAsync();
		recaptchaRef.current.reset();
		handleRegister({ ...formData, recaptchaValue: token });
	};

	const handleChangeNumOfCenter = ({ key, value }) => {
		if (!!value && (!/^[0-9]*$/.test(value) || value < 1)) {
			toast.error("必須輸入正分校數");
		} else {
			setFormData({ ...formData, [key]: value });
		}
	};

	const handleChange = ({ key, value }) => {
		setFormData({ ...formData, [key]: value });
	};

	const handleIsMultiCenterChange = (event) => {
		if (event.target.value === "false") {
			setFormData({ ...formData, isMultiCenter: event.target.value, numOfCenter: "1" });
		} else {
			setFormData({ ...formData, isMultiCenter: event.target.value });
		}
	};

	return (
		<Container>
			<LeftContainer>
				<LogoPart>
					<Logo src={headerLogo}></Logo>
				</LogoPart>

				<TextPart style={{ flex: 1, display: "flex", flexDirection: "column" }}>
					<TextSubPart>
						<WelcomeTitleText>歡迎!</WelcomeTitleText>
						<WelcomeText>
							進入
							<TitleLogo src={titleImg} />
						</WelcomeText>
						<WelcomeText>教育中心註冊頁面</WelcomeText>
					</TextSubPart>
					<MobileBlankBlock>&nbsp;</MobileBlankBlock>
					<TextSubPart>
						<Link style={{ textDecoration: "none" }} to={`/`}>
							<LoginPageText>登入頁面</LoginPageText>
						</Link>
					</TextSubPart>
				</TextPart>
			</LeftContainer>
			<RightContainer>
				<RightInnerContainer>
					<TitleTextRow>
						<TitleText>新用户註冊</TitleText>
						<MandatoryRemark>*必須填寫</MandatoryRemark>
					</TitleTextRow>
					<FieldsInRow>
						<LeftHalfSizeDiv>
							<TextFieldLabel>
								機構名稱<MandatoryRemark>*</MandatoryRemark>
							</TextFieldLabel>
							<TextField
								placeholder="機構名稱"
								style={{ backgroundColor: "white" }}
								value={formData.name}
								name="name"
								handleChange={handleChange}
								autoComplete={"name"}
							/>
						</LeftHalfSizeDiv>
						<RightHalfSizeDiv>
							<TextFieldLabel>
								電郵<MandatoryRemark>*</MandatoryRemark>
							</TextFieldLabel>
							<TextField
								placeholder="電郵"
								style={{ backgroundColor: "white" }}
								value={formData.email}
								name="email"
								handleChange={handleChange}
								autoComplete={"email"}
							/>
						</RightHalfSizeDiv>
					</FieldsInRow>

					<FieldsInRow>
						<LeftHalfSizeDiv>
							<TextFieldLabel>
								機構地址<MandatoryRemark>*</MandatoryRemark>
							</TextFieldLabel>
							<TextField
								placeholder="機構地址"
								style={{ backgroundColor: "white", height: "calc(100% - 134px)" }}
								value={formData.address}
								handleChange={handleChange}
								autoComplete={"address"}
								name="address"
								multiline
								rows={5}
							/>
							<TextFieldLabel>
								聯絡人名字<MandatoryRemark>*</MandatoryRemark>
							</TextFieldLabel>
							<TextField
								placeholder="聯絡人名字"
								style={{ backgroundColor: "white" }}
								value={formData.contactPerson}
								name="contactPerson"
								handleChange={handleChange}
							/>
						</LeftHalfSizeDiv>
						<RightHalfSizeDiv>
							<TextFieldLabel>
								電話<MandatoryRemark>*</MandatoryRemark>
							</TextFieldLabel>
							<TextField
								placeholder="電話"
								style={{ backgroundColor: "white" }}
								value={formData.phone}
								name="phone"
								handleChange={handleChange}
								autoComplete={"phone"}
							/>
							<TextFieldLabel>Whatsapp</TextFieldLabel>
							<TextField
								placeholder="Whatsapp"
								style={{ backgroundColor: "white" }}
								value={formData.whatsapp}
								name="whatsapp"
								handleChange={handleChange}
								autoComplete={"whatsapp"}
							/>
							<TextFieldLabel>Facebook連結</TextFieldLabel>
							<TextField
								placeholder="Facebook連結"
								style={{ backgroundColor: "white" }}
								value={formData.facebook}
								name="facebook"
								handleChange={handleChange}
							/>
						</RightHalfSizeDiv>
					</FieldsInRow>

					<FieldsInRow>
						<LeftHalfSizeDiv>
							<TextFieldLabel>網站</TextFieldLabel>
							<TextField
								placeholder="網站"
								style={{ backgroundColor: "white" }}
								value={formData.website}
								name="website"
								handleChange={handleChange}
							/>
						</LeftHalfSizeDiv>
						<RightHalfSizeDivCenter>
							<TextFieldLabel>
								需要管理多間分校嗎?<MandatoryRemark>*</MandatoryRemark>
							</TextFieldLabel>
							<Select
								value={formData.isMultiCenter}
								onChange={handleIsMultiCenterChange}
								input={<TextField value={"isMultiCenter"} style={{ backgroundColor: "white" }} fullWidth={false} />}
							>
								<MenuItem key={"Yes"} value={"true"}>
									{"需要"}
								</MenuItem>
								<MenuItem key={"No"} value={"false"}>
									{"不需要"}
								</MenuItem>
							</Select>
							<TextField
								style={{ marginLeft: 10, backgroundColor: "white", width: 100 }}
								fullWidth={false}
								value={formData.numOfCenter}
								name="numOfCenter"
								disabled={formData.isMultiCenter === "false"}
								handleChange={handleChangeNumOfCenter}
							/>
						</RightHalfSizeDivCenter>
					</FieldsInRow>

					<div style={{ flex: 30 }}></div>
					<ReCaptchaWithButton>
						<ReCaptchaContainer>
							<ReCAPTCHA badge={"inline"} ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_KEY} size={"invisible"} hl="zh-HK" />
						</ReCaptchaContainer>
						<ButtonContainer>
							<YellowButton label={"提交"} handleOnClick={handleOnClick} disabled={isFetching} />
						</ButtonContainer>
					</ReCaptchaWithButton>
				</RightInnerContainer>
			</RightContainer>
			<BlankBlock>&nbsp;</BlankBlock>
		</Container>
	);
};

RegisterContainer.propTypes = {
	handleRegister: PropTypes.func,
};

const Container = styled.div`
	height: 90%;
	width: 75%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
	display: flex;
	flex-direction: row;
	filter: drop-shadow(0px 0px 20px rgba(0, 0, 0));
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		width: calc(100% - 30px);
		flex-direction: column;
	}
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		min-width: 720px;
		min-height: 400px;
	}
`;

const LeftContainer = styled.div`
	display: flex;
	flex: 3;
	flex-direction: column;
	background-color: white;
	padding-left: 25px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex: 1;
		flex-direction: row;
		padding-top: 25px;
		padding-bottom: 25px;
	}
`;

const LogoPart = styled.div`
	border-bottom: #dbdbdb 1px solid;
	padding-bottom: 10px;
	margin-right: 25px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		border-bottom: 0;
	}
`;

const TextPart = styled.div`
	flex-direction: column;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		border-left: #dbdbdb 1px solid;
		padding-left: 15px;
		margin-left: 15px;
	}
`;

const Logo = styled.img`
	max-width: 100px;
	max-height: 100px;
	width: 100%;
	height: 100%;
	padding-left: 2px;
	padding-top: 24px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		max-width: 80px;
		max-height: 80px;
	}
`;

const RightContainer = styled.div`
	flex: 7;
	background-color: #112a84;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex: 4;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		overflow: auto;
	}
`;

const RightInnerContainer = styled.div`
	width: 85%;
	display: flex;
	flex-direction: column;
	height: 90%;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		width: 88%;
		height: auto;
	}
`;

const TitleText = styled.div`
	color: white;
	font-size: 36px;
	line-height: 1.5;
	font-weight: bold;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 24px;
		line-height: 2.1;
	}
`;

const MandatoryRemark = styled.span`
	color: #ffce00;
	font-size: 16px;
`;

const TitleTextRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
`;

const LeftHalfSizeDiv = styled.div`
	flex: 1;
	margin-right: 10px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		margin-right: 0;
	}
`;

const RightHalfSizeDiv = styled.div`
	flex: 1;
	margin-left: 10px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		margin-left: 0;
	}
`;

const RightHalfSizeDivCenter = styled.div`
	flex: 1;
	margin-left: 10px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		text-align: left !important;
		margin-left: 0;
		margin-bottom: 10px;
		> * {
			text-align: left !important;
		}
	}
`;

const FieldsInRow = styled.div`
	display: flex;
	flex-direction: row;
	widht: 100%;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		display: block;
	}
`;

const WelcomeTitleText = styled.div`
	color: #1f2e64;
	font-size: 46px;
	line-height: 1.5;
	font-weight: bold;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 30px;
	}
`;

const WelcomeText = styled.div`
	display: flex;
	flex-direction: row;
	color: #1f2e84;
	font-size: 26px;
	font-weight: bold;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		font-size: 20px;
	}
`;

const LoginPageText = styled.div`
	color: #0085b5;
	font-size: 16px;
	font-weight: bold;
	margin-bottom: 20px;
	cursor: pointer;
`;

const TextFieldLabel = styled.div`
	color: white;
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 5px;
	margin-top: 20px;
	text-align: left;
`;

const TitleLogo = styled.img`
	padding-top: 8px;
	width: 130px;
	height: 24px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		width: 94px;
		height: 17px;
	}
`;

const BlankBlock = styled.div`
	flex: 20;
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		display: none;
	}
`;

const MobileBlankBlock = styled.div`
	flex: 20;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		display: none;
	}
`;

const ButtonContainer = styled.div`
	padding: 15px 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	> button {
		width: 135px;
		font-weight: bold;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: 100%;
		}
	}
`;

const ReCaptchaWithButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: end;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex-direction: column;
		justify-content: stretch;
		align-items: stretch;
	}
`;

const ReCaptchaContainer = styled.div`
	padding-top: 10px;
	padding-bottom: 15px;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		padding-bottom: 0px;
	}
`;

const TextSubPart = styled.div``;
