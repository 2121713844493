import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

export const SubmitButton = (props) => {
	const { label, handleOnClick, style, active, buttonIcon, disabled } = props;

	return (
		<Button
			variant="contained"
			style={{
				padding: "8px 64px",
				backgroundColor: active ? "#333399" : "#eeeeee",
				borderColor: "#333399",
				color: active ? "white" : "#585858",
				fill: active ? "white" : "#585858",
				borderRadius: 40,
				...style,
			}}
			onClick={() => {
				if (!!handleOnClick && !!active && !disabled) {
					handleOnClick();
				}
			}}
			startIcon={buttonIcon}
			disabled={disabled}
		>
			{label}
		</Button>
	);
};

SubmitButton.propTypes = {
	label: PropTypes.string,
	handleOnClick: PropTypes.func,
	style: PropTypes.object,
	active: PropTypes.bool,
	buttonIcon: PropTypes.object,
	disabled: PropTypes.bool,
};

SubmitButton.defaultProps = {
	active: true,
	disabled: false,
};
