import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { learningCentreConstants, generalConstants } from "_constants";

import { learningCentreAction } from "actions";
import ProgressiveRejectDebouncer from "_util/debouncers/ProgressiveRejectDebouncer";

import moment from "moment";
import _ from "lodash";
import isEqual from "react-fast-compare";

import { useHistory } from "react-router-dom";
import { MOBILE_MAX_WIDTH } from "_constants";
import { Desktop } from "components/responsive";

import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import { Pagination, RefreshButton, Loading, BlockMobileOverlay } from "components/general";
import { StandardSelect, StandardTextField } from "components/general/standard";
import { AdminMenubar, AdminUserInfo, AdminSectionbar, AdminFooter } from "components/admin";
import {
	PageContainer,
	LeftContainer,
	RightContainer,
	ContentContainer,
	TableContainer,
	SearchBar,
	SearchBarLeft,
	SearchBarRight,
	PaginationContainer,
	Table,
	TableHeaderRow,
	TableRow,
	TableTh,
	THButton,
	TableTd,
} from "components/layout";
import { ReactComponent as LearningCenterIcon } from "resources/img/learningCentre_icon.svg";

const DEFAULT_LIMIT = 10;
const DEFAULT_PAGE = 1;
const FROM_CENTER = "learningCenter";
export const LearningCentreManagementPage = () => {
	const { navigationObject } = useSelector(
		({ centre }) => ({
			navigationObject: centre.navigationObject?.root,
		}),
		isEqual
	);

	const { learningCentreList } = useSelector(
		({ centre }) => ({
			learningCentreList: centre.navigationObject?.root?.learningCentreList,
		}),
		isEqual
	);

	const totalNumOfRecord = useSelector(({ centre }) => centre.navigationObject?.root?.totalNumOfRecord);
	const currentPage = useSelector(({ centre }) => centre.navigationObject?.root?.currentPage);
	const numOfPage = useSelector(({ centre }) => centre.navigationObject?.root?.numOfPage);
	const sortingKey = useSelector(({ centre }) => centre.navigationObject?.root?.sortingKey);

	const isFetching = useSelector(({ centre }) => centre.isFetching);
	const loggedIn = useSelector(({ auth }) => auth.loggedIn);

	const [filterDistrict, setFilterDistrict] = useState(navigationObject?.district);
	const [filterCategory, setFilterCategory] = useState(navigationObject?.category);
	const [searchWord, setSearchWord] = useState(navigationObject?.searchKey);
	const [sortingDirection, setSortingDirection] = useState(navigationObject?.sortingDirection);

	const timeoutRef = useRef(null);

	const dispatch = useDispatch();
	const [debouncer] = useState(() => new ProgressiveRejectDebouncer(0.5 * 1000));
	let reactHistory = useHistory();

	const handleSorting = (key) => {
		let direction = "DESC";
		if (sortingKey === key && sortingDirection === "DESC") {
			direction = "ASC";
		}
		setSortingDirection(direction);

		dispatch(
			learningCentreAction.getLearningCenters({
				search: searchWord,
				district: filterDistrict,
				category: filterCategory,
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: `${key}:${direction}`,
			})
		);
	};

	const handlePageChange = (pageNum) => {
		dispatch(
			learningCentreAction.getLearningCenters({
				search: searchWord || "",
				district: filterDistrict || "",
				category: filterCategory || "",
				l: DEFAULT_LIMIT,
				p: pageNum,
				sort: `${sortingKey}:${sortingDirection}`,
			})
		);
	};

	useEffect(() => {
		if (loggedIn) {
			dispatch(
				learningCentreAction.getLearningCenters({
					search: navigationObject?.searchKey || "",
					district: navigationObject?.district || "",
					category: navigationObject?.category || "",
					l: DEFAULT_LIMIT,
					p: currentPage,
					sort: `${navigationObject?.sortingKey}:${navigationObject?.sortingDirection}`,
				})
			);
		}
	}, [dispatch, loggedIn]);

	const handleRefreshFilter = () => {
		setFilterCategory("");
		setFilterDistrict("");
		setSearchWord("");
		dispatch(
			learningCentreAction.getLearningCenters({
				search: "",
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: sortingKey === "score" ? "name:ASC" : `${sortingKey}:${sortingDirection}`,
			})
		);
	};

	const getDistrict = (item) => {
		if (item === "") {
			return "/";
		}
		return learningCentreConstants.DATA.district[item].label;
	};

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	const handleSelectChange = ({ key, value }) => {
		let searchingWord = false;
		let useDefaultKey = false;

		if (key === "district") {
			setFilterDistrict(value);
		} else if (key === "category") {
			setFilterCategory(value);
		} else if (key === "searchWord") {
			if (searchWord && searchWord.trim().length > 0 && _.isEmpty(value)) {
				useDefaultKey = true;
			}
			setSearchWord(value);
			searchingWord = !_.isEmpty(value);
		}

		if (!debouncer.token()) {
			resetTimeout();
			timeoutRef.current = setTimeout(() => {
				dispatch(
					learningCentreAction.getLearningCenters({
						search: key === "searchWord" ? value : searchWord,
						district: key === "district" ? value : filterDistrict,
						category: key === "category" ? value : filterCategory,
						l: DEFAULT_LIMIT,
						p: DEFAULT_PAGE,
						sort: useDefaultKey ? "name:ASC" : searchingWord ? "score:DESC" : `${sortingKey}:${sortingDirection}`,
					})
				);
			}, 1000);
			return;
		}

		resetTimeout();
		dispatch(
			learningCentreAction.getLearningCenters({
				search: key === "searchWord" ? value : searchWord,
				district: key === "district" ? value : filterDistrict,
				category: key === "category" ? value : filterCategory,
				l: DEFAULT_LIMIT,
				p: DEFAULT_PAGE,
				sort: useDefaultKey ? "name:ASC" : searchingWord ? "score:DESC" : `${sortingKey}:${sortingDirection}`,
			})
		);
	};

	const HandleRowOnClick = (item) => {
		reactHistory.push({
			pathname: generalConstants.PATH.LEARNING_CENTER,
			state: { from: FROM_CENTER, sourceCenter: item },
		});
	};

	const renderTH = () => {
		return learningCentreConstants.TABLE_COLUMN.map((column) => {
			return (
				<StyledTableTh className={column.key} key={`${column.key}_table_header`}>
					<THButton key={`${column.key}_table_header`} type="button" onClick={() => handleSorting(column.key)}>
						<div>
							{column.value}
							{sortingKey === column.key ? (
								sortingDirection === "ASC" ? (
									<ArrowDropUp fontSize={"small"} style={{ verticalAlign: "middle" }} />
								) : (
									<ArrowDropDown fontSize={"small"} style={{ verticalAlign: "middle" }} />
								)
							) : null}
						</div>
					</THButton>
				</StyledTableTh>
			);
		});
	};
	const renderTableHeader = renderTH();

	const renderTD = () => {
		if (learningCentreList && learningCentreList.length > 0) {
			return learningCentreList.map((msg, index) => {
				const tempObj = {};
				learningCentreConstants.TABLE_COLUMN.forEach((column) => {
					tempObj[column.key] = msg[column.key];
				});

				return (
					<TableRow
						key={`${msg._id}_${index}_tr`}
						onClick={() => {
							HandleRowOnClick(msg);
						}}
					>
						{Object.keys(tempObj).map((columnName, index) => {
							if (columnName === "name") {
								var centreName = "";
								if (typeof tempObj[columnName] != "undefined") {
									centreName = tempObj[columnName];

									if (_.isEmpty(centreName)) {
										centreName = msg["nameEng"];
									}
								}
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{msg.status === learningCentreConstants.DATA.status.CLOSED.key && (
											<ClosedBadge>{learningCentreConstants.DATA.status[msg.status].label}</ClosedBadge>
										)}
										{centreName}
									</StyledTableTd>
								);
							} else if (columnName === "district") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{getDistrict(tempObj[columnName] || "")}
									</StyledTableTd>
								);
							} else if (columnName === "courseNum") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{tempObj[columnName]}
									</StyledTableTd>
								);
							} else if (columnName === "category") {
								var categoryName = "-";
								if (typeof tempObj[columnName] != "undefined" && typeof tempObj[columnName] != "string") {
									tempObj[columnName].forEach((name, index) => {
										if (index === 0) {
											categoryName = `${name}`;
										} else {
											categoryName = `${categoryName}、${name}`;
										}
									});
								}
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{categoryName}
									</StyledTableTd>
								);
							} else if (columnName === "lastUpdateTime") {
								return (
									<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
										{tempObj[columnName] ? moment(tempObj[columnName]).format("YYYY年M月DD日 hh:mmA") : "-"}
									</StyledTableTd>
								);
							}

							return (
								<StyledTableTd className={columnName} key={`${msg._id}_${columnName}_${index}_td`}>
									{tempObj[columnName]}
								</StyledTableTd>
							);
						})}
					</TableRow>
				);
			});
		}
	};
	const renderTableCell = renderTD();

	return (
		<React.Fragment>
			{!!isFetching && <Loading />}
			<PageContainer>
				<LeftContainer>
					<AdminMenubar section={generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key} />
				</LeftContainer>
				<RightContainer>
					<Desktop>
						<AdminUserInfo />
					</Desktop>
					<AdminSectionbar
						title={generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key}
						buttonLabel={"新增補習社"}
						sublabel={`補習社數量: ${totalNumOfRecord}`}
						buttonIcon={<LearningCenterIcon />}
						buttonType={generalConstants.BUTTON_TYPE.SUBMIT}
						buttonPath={{
							path: `${generalConstants.PATH.LEARNING_CENTER}/add`,
							stateObj: { from: FROM_CENTER },
						}}
						locationPathArray={[
							{
								title: generalConstants.TAB_NAME[generalConstants.NAV_TAB.LEARNING_CENTRE_MANAGEMENT.key],
								isAction: false,
								target: "",
							},
						]}
					/>
					<ContentContainer>
						<SearchBar>
							<SearchBarLeft>
								<RowInBlock marginBottom="0px">
									<div style={{ marginLeft: "10px", width: "20%" }}>
										<StandardSelect
											emptyText={"地區"}
											name={"district"}
											displayEmpty
											options={learningCentreConstants.OPTION.district}
											value={filterDistrict}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ marginLeft: "10px", width: "20%" }}>
										<StandardSelect
											emptyText={"課程類別"}
											name={"category"}
											displayEmpty
											options={learningCentreConstants.OPTION.category}
											value={filterCategory}
											handleChange={handleSelectChange}
										/>
									</div>
									<div style={{ marginLeft: "10px", width: "40%" }}>
										<StandardTextField
											placeholder="關鍵字"
											value={searchWord}
											name="searchWord"
											handleChange={handleSelectChange}
											style={{
												borderTopRightRadius: "0px",
												borderBottomRightRadius: "0px",
											}}
										/>
									</div>
									<RefreshButton style={{ marginLeft: "5px" }} handleOnClick={handleRefreshFilter} label="重置選項"></RefreshButton>
								</RowInBlock>
							</SearchBarLeft>
							<Desktop>
								<SearchBarRight />
							</Desktop>
						</SearchBar>
						<TableContainer>
							<Table>
								<thead>
									<TableHeaderRow>{renderTableHeader}</TableHeaderRow>
								</thead>
								<tbody>{renderTableCell}</tbody>
							</Table>
						</TableContainer>
						<PaginationContainer>
							<Pagination count={numOfPage} page={currentPage} handlePageChange={handlePageChange} />
						</PaginationContainer>
					</ContentContainer>
					<AdminFooter />
				</RightContainer>
			</PageContainer>
			<BlockMobileOverlay />
		</React.Fragment>
	);
};

const RowInBlock = styled.div`
	display: flex;
	margin-bottom: ${(props) => props.marginBottom || "10px"};
	flex-direction: row;
`;

const ClosedBadge = styled.span`
	padding: 2px 6px 3px;
	border-radius: 3px;
	background-color: #fff0f2;
	margin: 9px 6px 9px 0;
	font-size: 10px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #ff0000;
`;

const StyledTableTh = styled(TableTh)`
	&.name {
		width: 40%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 0;
			z-index: 2;
			position: sticky;
			width: calc(30vw);
		}
	}
	&.district {
		width: 10%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(20vw);
		}
	}
	&.courseNum {
		width: 10%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(20vw);
		}
	}
	&.category {
		width: 20%;
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			width: calc(40vw);
		}
	}
	&.lastUpdateTime {
		width: 170px;
	}
`;

const StyledTableTd = styled(TableTd)`
	background-color: #fff;
	&.name {
		@media (max-width: ${MOBILE_MAX_WIDTH}) {
			left: 0;
			z-index: 2;
			position: sticky;
			width: calc(30vw);
		}
	}
`;
