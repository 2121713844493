import axios from "axios";

export const learningCentreService = {
	createLearningCenter,
	updateLearningCenter,
	getLearningCenters,
	getLearningCenterByKey,
	getLearningCenterByKeyWithoutData,
	deleteLearningCenter,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
	},
	withCredentials: true,
};

function createFormData(data) {
	let formData = new FormData();

	for (let key in data) {
		let value = data[key];
		if (Array.isArray(value)) {
			value = JSON.stringify(value);
		}
		formData.append(key, value);
	}
	return formData;
}

async function deleteLearningCenter(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/learningCenter/${data.key}`,
		...httpOptions,
		method: "DELETE",
		params: {
			rev: data.rev,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

async function createLearningCenter(data) {
	let formData = createFormData(data);

	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/learningCenter/`,
		...httpOptions,
		headers: {
			...httpOptions.headers,
			"Content-Type": "multipart/form-data",
		},
		method: "POST",
		data: formData,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function updateLearningCenter(data) {
	let formData = createFormData(data);
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/learningCenter/`,
		...httpOptions,
		headers: {
			...httpOptions.headers,
			"Content-Type": "multipart/form-data",
		},
		method: "PUT",
		data: formData,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getLearningCenters(data) {
	const formData = data ? { ...data } : undefined;
	if (formData?.district && formData?.district.trim().length > 0) {
		let singleElementArray = [];
		singleElementArray.push(formData.district);
		formData.district = singleElementArray;
	} else if (formData) {
		delete formData.district;
	}
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/learningCenter/`,
		...httpOptions,
		method: "GET",
		params: formData,
		cache: false,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getLearningCenterByKey(key, data) {
	const formData = data ? { ...data } : undefined;
	if (formData?.district && formData?.district.trim().length > 0) {
		let singleElementArray = [];
		singleElementArray.push(formData.district);
		formData.district = singleElementArray;
	} else if (formData) {
		delete formData.district;
	}
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/learningCenter/${key}`,
		...httpOptions,
		method: "GET",
		params: formData,
		cache: false,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getLearningCenterByKeyWithoutData(key) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/learningCenter/${key}`,
		...httpOptions,
		method: "GET",
		cache: false,
	})
		.then(handleResponse)
		.catch(handleError);
}

function handleError(err) {
	const msg = (err.response && err.response.data.message) || err.message || err;
	throw { error: msg };
}
function handleResponse(res) {
	return res.data;
}
