import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { history } from "_util/_helper";
import { Link } from "react-router-dom";

import { Desktop, desktop } from "components/responsive";
import { generalConstants, MOBILE_MAX_WIDTH, DESKTOP_MIN_WIDTH } from "_constants";
import { WarningButton, ImportButton, SubmitButton } from "components/general";

export const AdminSectionbar = (props) => {
	const { title, buttonLabel, label, sublabel, buttonIcon, buttonType, buttonPath, bigSublabel, locationPathArray } = props;

	return (
		<Container>
			<SectionLayer>
				<RowInBlock style={{ justifyContent: "space-between" }}>
					<BlockLeft>
						{sublabel && (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								<Title>{label ? label : generalConstants.TAB_NAME[title]}</Title>
								<Desktop>{bigSublabel ? <BigSubTitle>- {sublabel} </BigSubTitle> : <SubTitle>( {sublabel} )</SubTitle>}</Desktop>
							</div>
						)}
						{!sublabel && <Title>{label ? label : generalConstants.TAB_NAME[title]}</Title>}
						{locationPathArray.length > 1 && (
							<RowInBlock style={{ height: "20px", alignItems: "center" }}>
								{locationPathArray.map((path, index) => {
									return (
										<RowInBlock key={`location_path_${index}`} style={{ height: "20px", alignItems: "center" }}>
											{path.isBack ? (
												<LocationTitle
													onClick={() => {
														history.goBack(path.target);
													}}
												>
													{path.title}
												</LocationTitle>
											) : path.isAction ? (
												<Link style={{ textDecoration: "none" }} to={{ pathname: path.target, state: path.stateObj }}>
													<LocationTitle>{path.title}</LocationTitle>
												</Link>
											) : (
												<LocationPath>{path.title}</LocationPath>
											)}
											{locationPathArray.length - 1 !== index && (
												<LocationPath
													style={{
														paddingLeft: "5px",
														paddingRight: "5px",
													}}
												>{` > `}</LocationPath>
											)}
										</RowInBlock>
									);
								})}
							</RowInBlock>
						)}
					</BlockLeft>
					<BlockRight>
						{buttonLabel && buttonPath && buttonType === generalConstants.BUTTON_TYPE.WARNING && (
							<Link style={{ textDecoration: "none" }} to={{ pathname: buttonPath.path, state: buttonPath.stateObj }}>
								<WarningButton label={buttonLabel} buttonIcon={buttonIcon} />
							</Link>
						)}
						{buttonLabel && buttonPath && buttonType === generalConstants.BUTTON_TYPE.IMPORT && (
							<Link style={{ textDecoration: "none" }} to={{ pathname: buttonPath.path, state: buttonPath.stateObj }}>
								<ImportButton label={buttonLabel} buttonIcon={buttonIcon} />
							</Link>
						)}
						{buttonLabel && buttonPath && buttonType === generalConstants.BUTTON_TYPE.SUBMIT && (
							<Link style={{ textDecoration: "none" }} to={{ pathname: buttonPath.path, state: buttonPath.stateObj }}>
								<SubmitButton style={{ padding: `8px ${desktop() ? "34px" : "15px"}` }} label={buttonLabel} buttonIcon={buttonIcon} />
							</Link>
						)}
					</BlockRight>
				</RowInBlock>
			</SectionLayer>
		</Container>
	);
};

AdminSectionbar.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string,
	sublabel: PropTypes.string,
	buttonLabel: PropTypes.string,
	buttonType: PropTypes.string,
	buttonIcon: PropTypes.object,
	buttonPath: PropTypes.object,
	bigSublabel: PropTypes.bool,
	locationPathArray: PropTypes.array,
};

AdminSectionbar.defaultProps = {
	buttonType: generalConstants.BUTTON_TYPE.WARNING,
	buttonPath: null,
	locationPathArray: [],
};

const Container = styled.div`
	width: 100%;
`;

const SectionLayer = styled.div`
	background-color: #f2f2f2;
	padding: 5px 20px;
	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		height: 60px;
	}
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		padding-top: 12px;
		padding-bottom: 12px;
	}
`;

const RowInBlock = styled.div`
	height: 100%;
	display: flex;
	flex-direction: row;
`;

const BlockLeft = styled.div`
	flex: 1;
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex: 3;
	}
`;
const BlockRight = styled.div`
	flex: 0 0 200px;
	text-align: right;

	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		padding-top: 12px;
	}
	@media (max-width: ${MOBILE_MAX_WIDTH}) {
		flex: 2;
	}
`;

const Title = styled.div`
	font-size: 24px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #333399;
	max-width: 90%;

	@media (min-width: ${DESKTOP_MIN_WIDTH}) {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

const SubTitle = styled.div`
	margin: 10px 10px 6px 10px;
	font-size: 11px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.55;
	letter-spacing: normal;
	text-align: left;
	color: #666666;
`;

const LocationTitle = styled.div`
	color: #0085b5;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.5;
	text-align: left;
	max-width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const LocationPath = styled.div`
	color: #333333;
	font-size: 12px;
	font-weight: bold;
	line-height: 1.5;
	text-align: left;
	max-width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const BigSubTitle = styled.div`
	margin: 5px 5px 6px 10px;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.45;
	letter-spacing: normal;
	color: #333399;
`;
