import axios from "axios";

export const authService = {
	login,
	logout,
	getOrganization,
	updateOrganization,
	forgetPassword,
	changePassword,
	register,
	resetPassword,
	resendEmail,
};

const httpOptions = {
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
	},
	withCredentials: true,
};

function createFormData(data) {
	let formData = new FormData();

	for (let key in data) {
		let value = data[key];
		if (Array.isArray(value)) {
			value = JSON.stringify(value);
		}
		if (key === "profilePic" && value === "") {
			continue;
		} else {
			formData.append(key, value);
		}
	}
	return formData;
}

async function resendEmail(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization/resendEmail`,
		...httpOptions,
		data,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function register(form) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization/register`,
		...httpOptions,
		data: form,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function updateOrganization(data) {
	let formData = createFormData(data);
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization`,
		...httpOptions,
		headers: {
			...httpOptions.headers,
			"Content-Type": "multipart/form-data",
		},
		method: "PUT",
		cache: true,
		data: formData,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function getOrganization(forceUpdate) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization`,
		...httpOptions,
		method: "GET",
		cache: !forceUpdate,
	})
		.then(handleResponse)
		.catch(handleError);
}

async function changePassword(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization/changePassword`,
		...httpOptions,
		data: data,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function forgetPassword(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization/forgetPassword`,
		...httpOptions,
		data: data,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function resetPassword(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization/resetPassword`,
		...httpOptions,
		data: data,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function login(data) {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization/login`,
		...httpOptions,
		data,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

async function logout() {
	return axios({
		url: `${process.env.REACT_APP_BACKEND_URL}/organization/logout`,
		...httpOptions,
		method: "POST",
	})
		.then(handleResponse)
		.catch(handleError);
}

function handleError(err) {
	const msg = (err.response && err.response.data.message) || err.message || err;
	throw { error: msg };
}

function handleResponse(res) {
	return res.data;
}
