export const AvatarHelper = (src) => {
	
	if (src) {
		if (src.startsWith("http")) {
            //console.log("avatar:"+src);
			return src;
		} else {
            //console.log("avatar2:"+`${process.env.REACT_APP_BACKEND_URL}}/socialNetwork/profilepic/${src}`);
			return `${process.env.REACT_APP_BACKEND_URL}/socialNetwork/profilepic/${src}`;
		}
	}
    return null;
    
};