import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import learningCentreReducer from "./learningCentre.reducer";
import learningCoursesReducer from "./learningCourses.reducer";
import { authConstants } from "_constants";

const appReducer = combineReducers({
	auth: authReducer,
	centre: learningCentreReducer,
	course: learningCoursesReducer,
});

const rootReducer = (state, action) => {
	// clear all state from LOGOUT action.
	if (action.type === authConstants.type.LOGOUT) {
		state = {
			auth: state.auth,
		};
	}

	return appReducer(state, action);
};

export default rootReducer;
