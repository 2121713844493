import React from "react";
import PropTypes from "prop-types";

import { AdminDesktopMenubar } from "./AdminDesktopMenubar";
import { AdminMobileMenubar } from "./AdminMobileMenubar";

import { Desktop, Mobile } from "components/responsive";
//advertising

export const AdminMenubar = (props) => {
	
	return (
		<>
			<Desktop>
				<AdminDesktopMenubar {...props}/>
			</Desktop>
			<Mobile>
				<AdminMobileMenubar  {...props}/>
			</Mobile>
		</>
	);
};

AdminMenubar.propTypes = {
	section: PropTypes.string,
	label: PropTypes.string,
	sublabel: PropTypes.string,
	buttonLabel: PropTypes.string,
	handleOnClick: PropTypes.func,
};
